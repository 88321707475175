import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";
import { useResource } from "hooks/useResource";

import { CheckboxField } from "components/_inputs/CheckboxField";
import { CollapsibleName } from "components/CollapsibleName";

import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { TextTruncator } from "components/TextTruncator";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

type Props = {
  setLocalSelectedCategories: Dispatch<SetStateAction<string[]>>;
  localSelectedCategories: string[];
};

const LOAD_MORE_LIMIT = 50;

export const PinnedCommentCategoriesFilter = ({
  localSelectedCategories,
  setLocalSelectedCategories,
}: Props) => {
  const [state] = useContext(AnalysisContext);

  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false);
  const [incrementedValue, setIncrementedValue] = useState<number>(LOAD_MORE_LIMIT);

  const { getResource } = useResource();

  const handleSelectAll = () => {
    if (state.availableCategories.length === localSelectedCategories.length)
      return setLocalSelectedCategories([]);
    setLocalSelectedCategories(state.availableCategories.map((cat) => cat.name));
  };

  const handleSelectFilter = (selectedCategory: string) => {
    const filterArray = [...localSelectedCategories];
    const updatedFilters = localSelectedCategories.some((lsc) => lsc === selectedCategory)
      ? filterArray.filter((f) => f !== selectedCategory)
      : [...filterArray, selectedCategory];
    setLocalSelectedCategories(updatedFilters);
  };

  return (
    <>
      <CollapsibleName
        name={getResource(
          localSelectedCategories?.length > 0
            ? "sideDrawer.filters.pinnedComments.categories.count"
            : "sideDrawer.filters.pinnedComments.categories",
          localSelectedCategories?.length > 0 ? localSelectedCategories?.length : undefined
        )}
        isExpanded={categoriesExpanded}
        onClick={() => setCategoriesExpanded(!categoriesExpanded)}
      />
      <StyledCheckboxContainer>
        {categoriesExpanded && (
          <>
            <CheckboxField
              variant={
                localSelectedCategories?.length === state.availableCategories?.length
                  ? CheckboxCheckedAppearance.Default
                  : CheckboxCheckedAppearance.Partial
              }
              label={getResource("columnFilterSection.selectAll")}
              onChange={handleSelectAll}
              checked={localSelectedCategories?.length > 0}
            />
            {state.availableCategories.map(
              (cat, index) =>
                index + 1 <= incrementedValue && (
                  <CheckboxField
                    key={index}
                    label={<TextTruncator value={cat.name} customWidth="250px" />}
                    onChange={() => handleSelectFilter(cat.name)}
                    checked={
                      !!localSelectedCategories?.find((selectedCat) => selectedCat === cat.name)
                    }
                  />
                )
            )}
            {state.availableCategories.length > LOAD_MORE_LIMIT &&
            incrementedValue <= state.availableCategories.length ? (
              <Button
                variant={ButtonVariant.secondary}
                size={ButtonSize.sm}
                style={{ backgroundColor: Color.white, fontWeight: "normal" }}
                onClick={() => setIncrementedValue(incrementedValue + LOAD_MORE_LIMIT)}
              >
                <Text resource="button.loadMore" />
              </Button>
            ) : state.availableCategories.length > LOAD_MORE_LIMIT ? (
              <Button
                variant={ButtonVariant.secondary}
                size={ButtonSize.sm}
                style={{ backgroundColor: Color.white }}
                onClick={() => setIncrementedValue(LOAD_MORE_LIMIT)}
              >
                <Text resource="button.showLess" />
              </Button>
            ) : null}
          </>
        )}
      </StyledCheckboxContainer>
    </>
  );
};

const StyledCheckboxContainer = styled.div`
  margin-left: 15px;
  margin-bottom: 15px;
`;
