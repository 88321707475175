import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppState } from "store/appReducer";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import {
  closeRedactCommentModal,
  setIsRedactingComment,
  setIsRevertingComment,
  setRevertCommentModalOpen,
} from "../redactCommentSlice";
import { setEmptyStateType } from "../redactionListSlice";
import { setEmptyStateType as setVersionsEmptyStateType } from "../redactionVersionsSlice";
import {
  setBulkActionToBlock,
  setEmptyStateType as setRequestsEmptyStateType,
} from "../redactionRequestListSlice";
import { setIsValidToSave, setRedactionSetId } from "../redactionBuilderSlice";
import { formatSearchForFetchRequest } from "utils/formatters";
import { unique } from "utils/unique";

import {
  createRedactionSet,
  createRedactionVersion,
  getAnalysisRedactionSets,
  createSystemRedactionVersion,
  getAnalysisRedactionVersions,
  getCommentRedactionDetails,
  getRedactionLists,
  getRedactionSet,
  getRedactionSetTerms,
  getRedactionVersion,
  getRedactionVersions,
  updateAnalysisRedactionVersion,
  updateCommentRedaction,
  updateRedactionSet,
  updateRedactionVersion,
} from "services/redactions";

import {
  CommentRedactionApiData,
  RedactionTableApiData,
  RedactionVersion,
  RedactionVersionsTableApiData,
} from "ts/redaction";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { RadioOption } from "ts/radio";
import {
  RedactionAction,
  RedactionDisplayMethod,
  RedactionListSortingParameter,
  RedactionListType,
  RedactionRequestStatus,
  SortingOrder,
} from "@explorance/mly-types";

import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { setDataFromApi } from "../redactionRequestManagementSlice";
import { RedactionRequestTableApiData } from "ts/redactionRequest";
import {
  createRedactionRequestsByCommentId,
  getRedactionRequestCount,
  getRedactionRequestList,
  getRedactionRequestsByCommentId,
  updateRedactionRequest,
  updateRedactionRequests,
} from "services/redactionRequests";
import { setModalOpen, setNewRedactionVersionId } from "../redactionModalSlice";

export type RedactionSetType = {
  name: string;
  type: RedactionListType;
  termCount: number;
  terms: string[];
  includeVariations?: boolean;
};

export const fetchRedactionLists = createAsyncThunk<
  RedactionTableApiData,
  void,
  { state: AppState }
>(
  "redaction/fetchRedactionLists",
  async (_, { getState, dispatch }): Promise<RedactionTableApiData> => {
    const state = getState();
    const redactionListState = state.redactionList;
    const searchTerm = state.search.searchTerm;

    const { data: redactionTableApiData } = await getRedactionLists(
      redactionListState.sortColumn,
      redactionListState.sortOrder,
      formatSearchForFetchRequest(searchTerm),
      DEFAULT_LIST_LIMIT,
      redactionListState.currentPage
    );

    const emptyStateType =
      redactionTableApiData.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : redactionTableApiData.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    return redactionTableApiData;
  }
);

export const fetchRedactionSet = createAsyncThunk<RedactionSetType, void, { state: AppState }>(
  "fetchRedactionSet",
  async (_, { getState }): Promise<RedactionSetType> => {
    const state = getState();
    return (await getRedactionSet(state.redactionBuilder.redactionSetId)).data;
  }
);

export const fetchAnalysisRedactionSets = createAsyncThunk<RedactionTableApiData, number>(
  "fetchAnalysisRedactionSets",
  async (analysisId): Promise<RedactionTableApiData> => {
    const { data: redactionSets } = await getAnalysisRedactionSets(analysisId);

    return redactionSets;
  }
);

export const saveRedactionSet = createAsyncThunk<void, void, { state: AppState }>(
  "saveRedactionSet",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const requestBody = {
        name: state.redactionBuilder.redactionFileName,
        type: state.redactionBuilder.redactionType,
        terms: unique(state.redactionBuilder.inputtedRedactionList),
        includeVariations: state.redactionBuilder.isVariationCheckboxChecked,
      };
      const { data } = await createRedactionSet(requestBody);
      dispatch(setRedactionSetId(Number(data.redactionSetId)));
      dispatch(setIsValidToSave(true));
      dispatch(showToastSuccess("toast.saveRedactionSuccessful"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

export const editRedactionSet = createAsyncThunk<void, number, { state: AppState }>(
  "editRedactionSet",
  async (id: number, { getState, dispatch }) => {
    const state = getState();
    try {
      const requestBody = {
        id,
        name: state.redactionBuilder.redactionFileName,
        terms: unique(state.redactionBuilder.inputtedRedactionList),
        includeVariations: state.redactionBuilder.isVariationCheckboxChecked,
      };
      await updateRedactionSet(requestBody);
      dispatch(showToastSuccess("toast.saveRedactionSuccessful"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

export const fetchAnalysisRedactionVersions = createAsyncThunk<RedactionVersion[], number>(
  "fetchAnalysisRedactionVersions",
  async (analysisId): Promise<RedactionVersion[]> => {
    return (await getAnalysisRedactionVersions(analysisId)).data.redactionVersions;
  }
);

export const fetchRedactionSets = createAsyncThunk<
  RedactionTableApiData,
  void,
  { state: AppState }
>("fetchRedactionSets", async (_, { getState }): Promise<RedactionTableApiData> => {
  const state = getState();
  const searchTerm = state.search.searchTerm.trim();
  const { data: redactionSets } = await getRedactionLists(
    RedactionListSortingParameter.LastUpdated,
    SortingOrder.DESC,
    formatSearchForFetchRequest(searchTerm),
    1000,
    1
  );

  return redactionSets;
});

export const handleCreateRedactionVersion = createAsyncThunk<void, number, { state: AppState }>(
  "handleCreateRedactionVersion",
  async (analysisId, { getState, dispatch }) => {
    const state = getState().redactionModal;

    const payload = {
      name: state.redactionVersionName,
      rules: state.redactionRules,
      includeManual: state.isManualRedactionEnabled,
    };

    try {
      const { data } = analysisId
        ? await createRedactionVersion(analysisId, payload)
        : await createSystemRedactionVersion(payload);

      dispatch(setNewRedactionVersionId(data.redactionVersionId));
      dispatch(showToastSuccess("toast.redactionVersionCreated"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
    }
  }
);

export const handleEditRedactionVersion = createAsyncThunk<void, void, { state: AppState }>(
  "handleEditRedactionVersion",
  async (_, { getState, dispatch }) => {
    const state = getState().redactionModal;

    try {
      await updateRedactionVersion(state.redactionVersionId, {
        name: state.redactionVersionName,
        rules: state.redactionRules,
        includeManual: state.isManualRedactionEnabled,
      });
      dispatch(showToastSuccess("toast.redactionVersionSaved"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

type changeAnalysisRedactionVersionProps = {
  versionOption: RadioOption;
  analysisId: number;
};

export const changeAnalysisRedactionVersion = createAsyncThunk<
  RadioOption,
  changeAnalysisRedactionVersionProps
>("changeAnalysisRedactionVersion", async ({ versionOption, analysisId }) => {
  await updateAnalysisRedactionVersion(Number(versionOption?.value) || null, analysisId);
  return versionOption;
});

export const fetchRedactionVersion = createAsyncThunk<RedactionVersion, void, { state: AppState }>(
  "fetchRedactionVersion",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const { data } = await getRedactionVersion(state.redactionModal.redactionVersionId);
      return data;
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      dispatch(setModalOpen(false));
    }
  }
);

export const fetchRedactionRequests = createAsyncThunk<
  RedactionRequestTableApiData,
  void,
  { state: AppState }
>("fetchRedactionRequests", async (_, { getState, dispatch }) => {
  const state = getState();
  const redactionRequestListState = state.redactionRequestList;
  const searchTerm = state.search.searchTerm.trim();

  const { data: redactionRequests } = await getRedactionRequestList(
    redactionRequestListState.listView,
    redactionRequestListState.sortColumn,
    redactionRequestListState.sortOrder,
    formatSearchForFetchRequest(searchTerm),
    DEFAULT_LIST_LIMIT,
    redactionRequestListState.currentPage,
    redactionRequestListState.currentStatusFilters
  );

  const emptyStateType =
    redactionRequests.itemCount === 0 &&
    (state.search.searchTerm.length > 0 ||
      redactionRequestListState.currentStatusFilters.length > 0)
      ? EmptyStateType.noSearchResults
      : redactionRequests.itemCount === 0
      ? EmptyStateType.noListResults
      : null;

  dispatch(setRequestsEmptyStateType(emptyStateType));

  return redactionRequests;
});

export const fetchRedactionRequestCount = createAsyncThunk<number, void>(
  "fetchRedactionRequestCount",
  async () => {
    const { data } = await getRedactionRequestCount(true, RedactionRequestStatus.Pending);
    return data.requestCount;
  }
);

export const fetchCommentRedactionDetails = createAsyncThunk<CommentRedactionApiData, number>(
  "fetchCommentRedactionDetails",
  async (commentId) => {
    return (await getCommentRedactionDetails(commentId)).data;
  }
);

export const redactComment = createAsyncThunk<void, void, { state: AppState }>(
  "redactComment",
  async (_, { getState, dispatch }) => {
    dispatch(setIsRedactingComment(true));
    try {
      const { displayMethod, redactedCommentValue, commentId } = getState().redactComment;
      const requestBody = {
        redactionDisplayMethod: displayMethod,
        redactionAction: RedactionAction.Redact,
        ...([RedactionDisplayMethod.Partial, RedactionDisplayMethod.Total].includes(
          displayMethod
        ) && {
          redactionValue: redactedCommentValue.trim(),
        }),
      };
      await updateCommentRedaction(commentId, requestBody);
      dispatch(closeRedactCommentModal());
      dispatch(showToastSuccess("toast.commentRedactedSuccessfully"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    } finally {
      dispatch(setIsRedactingComment(false));
    }
  }
);

export const revertCommentRedaction = createAsyncThunk<void, void, { state: AppState }>(
  "revertCommentRedaction",
  async (_, { getState, dispatch }) => {
    dispatch(setIsRevertingComment(true));
    dispatch(setRevertCommentModalOpen(false));
    try {
      const { commentId } = getState().redactComment;
      await updateCommentRedaction(commentId, {
        redactionAction: RedactionAction.Revert,
      });
      dispatch(closeRedactCommentModal());
      dispatch(showToastSuccess("toast.commentRedactionRevertedSuccessfully"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    } finally {
      setIsRevertingComment(false);
    }
  }
);

type patchRedactionRequestParams = {
  redactionAction: RedactionAction;
  redactionRequestId: number;
  editedValue?: string;
  editedDisplayMethod?: RedactionDisplayMethod;
};

export const patchRedactionRequest = createAsyncThunk<void, patchRedactionRequestParams>(
  "patchRedactionRequest",
  async (
    { redactionAction, redactionRequestId, editedValue, editedDisplayMethod },
    { dispatch }
  ) => {
    try {
      await updateRedactionRequest(redactionRequestId, {
        redactionAction,
        editedValue,
        editedDisplayMethod,
      });
      dispatch(showToastSuccess(`toast.redactionRequest.change.[${redactionAction}]`));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

type patchRedactionRequestsParams = {
  redactionAction: RedactionAction;
  requestIds: number[];
};

export const patchRedactionRequests = createAsyncThunk<void, patchRedactionRequestsParams>(
  "patchRedactionRequests",
  async (body, { dispatch }) => {
    try {
      await updateRedactionRequests(body);
      dispatch(setBulkActionToBlock(body.redactionAction));
      dispatch(showToastSuccess(`toast.redactionRequest.change.[${body.redactionAction}]`));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

export const fetchRedactionSetTerms = createAsyncThunk<string[], void, { state: AppState }>(
  "fetchRedactionSetTerms",
  async (_, { getState }) => {
    const state = getState().redactionBuilder;
    const { data } = await getRedactionSetTerms(state.redactionSetId, state.page);
    return data.redactionTerms;
  }
);

export const fetchRedactionRequestsForComment = createAsyncThunk<void, void, { state: AppState }>(
  "fetchRedactionRequestsForComment",
  async (_, { getState, dispatch }) => {
    try {
      const { commentId } = getState().redactionRequestManagement;
      const { data } = await getRedactionRequestsByCommentId(commentId);
      dispatch(setDataFromApi(data));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

export const submitRedactionRequest = createAsyncThunk<void, void, { state: AppState }>(
  "submitRedactionRequest",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { commentId, redactedCommentValue, displayMethod } = state.redactComment;

    const requestBody = {
      commentId,
      ...([RedactionDisplayMethod.Partial, RedactionDisplayMethod.Total].includes(
        displayMethod
      ) && {
        redactedValue: redactedCommentValue.trim(),
      }),
      redactionDisplayMethod: displayMethod,
    };

    dispatch(setIsRedactingComment(true));

    try {
      await createRedactionRequestsByCommentId(requestBody);
      dispatch(showToastSuccess("toast.redactionRequestSuccesfullySent"));
      dispatch(closeRedactCommentModal());
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    } finally {
      dispatch(setIsRedactingComment(false));
    }
  }
);

export const fetchRedactionVersionsLists = createAsyncThunk<
  RedactionVersionsTableApiData,
  void,
  { state: AppState }
>(
  "redaction/fetchRedactionVersionsLists",
  async (_, { getState, dispatch }): Promise<RedactionVersionsTableApiData> => {
    const state = getState();
    const redactionListState = state.redactionVersionsList;
    const searchTerm = state.search.searchTerm;

    const { data: redactionVersionsTableApiData } = await getRedactionVersions(
      redactionListState.sortColumn,
      redactionListState.sortOrder,
      formatSearchForFetchRequest(searchTerm),
      DEFAULT_LIST_LIMIT,
      redactionListState.currentPage
    );

    const emptyStateType =
      redactionVersionsTableApiData.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : redactionVersionsTableApiData.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setVersionsEmptyStateType(emptyStateType));

    return redactionVersionsTableApiData;
  }
);
