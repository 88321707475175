import { createAsyncThunk } from "@reduxjs/toolkit";
import { useResource } from "hooks/useResource";
import {
  getModelStatisticsFromData,
  typeLicensePeriodsForDropdown,
} from "pages/licensing/_layouts/blocks/helpers";
import {
  getLicensePeriodById,
  getLicensePeriods,
  getLicensePeriodStatistics,
  getModelLicenseList,
  synchronizeModels,
  updateModelActivation,
} from "services/licensing";
import { AppState } from "store/appReducer";
import { getLicensePeriodFromData, LicensePeriod, ModelLicense } from "ts/licensing";
import { setModelStatistics, setPercentageUsed, setTotalCommentsAnalyzed } from "./quotaMenuSlice";
import { useGetModelName } from "hooks/useGetModelName";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";

type UpdateModelActivationParams = {
  modelId: number;
  status: boolean;
};

export const syncModels = createAsyncThunk<ModelLicense[], void, { state: AppState }>(
  "modelManagement/syncModels",
  async (_, { dispatch }): Promise<ModelLicense[]> => {
    const { data } = await synchronizeModels();
    dispatch(
      data.models
        ? showToastSuccess("toast.synchronizationSuccessful")
        : showToastError("toast.defaultError")
    );
    return data.models;
  }
);

export const fetchModelLicenseList = createAsyncThunk<ModelLicense[], void, { state: AppState }>(
  "modelManagement/fetchModelLicenseList",
  async (): Promise<ModelLicense[]> => {
    const { data } = await getModelLicenseList();
    return data.models;
  }
);

export const updateModelActivationStatus = createAsyncThunk<
  UpdateModelActivationParams,
  UpdateModelActivationParams,
  { state: AppState }
>("modelManagement/updateModelActivationStatus", async (params) => {
  const { modelId, status } = params;
  await updateModelActivation(modelId, status);
  return { modelId, status };
});

export const fetchLicensePeriodById = createAsyncThunk<LicensePeriod, number, { state: AppState }>(
  "quotaMenu/fetchLicensePeriodById",
  async (periodId: number) => {
    const { data } = await getLicensePeriodById(periodId);
    return data;
  }
);

export const fetchLicensePeriods = createAsyncThunk<Record<string, any>, void, { state: AppState }>(
  "quotaMenu/fetchLicensePeriods",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { getResource } = useResource(
      state.auth.currentUser.preferredLanguage,
      state.resourcesSlice.resources,
      state.resourcesSlice.topicResources
    );

    const { data } = await getLicensePeriods();

    if (!data) {
      throw new Error("Failed to fetch licensing period data");
    }
    const typedLicensingPeriods = data.licencePeriods.map((lp) => getLicensePeriodFromData(lp));
    const typedDropdownOptions = typedLicensingPeriods.map((lp) =>
      typeLicensePeriodsForDropdown(lp, getResource)
    );
    const currentPeriod = typedDropdownOptions.find(
      (o) => parseInt(o.value) === typedLicensingPeriods.find((lp) => lp.isActive)?.id
    );
    dispatch(fetchLicensePeriodStatistics(parseInt(currentPeriod.value)));
    return { typedLicensingPeriods, typedDropdownOptions, currentPeriod };
  }
);

export const fetchLicensePeriodStatistics = createAsyncThunk<number, number, { state: AppState }>(
  "quotaMenu/fetchLicensePeriodStatistics",
  async (periodId: number, { getState, dispatch }) => {
    const state = getState();
    const { getResource } = useResource(
      state.auth.currentUser.preferredLanguage,
      state.resourcesSlice.resources,
      state.resourcesSlice.topicResources
    );
    const getModelName = useGetModelName(getResource);

    const { data } = await getLicensePeriodStatistics(periodId);
    if (!data) throw new Error("Failed to fetch license period statistics");

    dispatch(setTotalCommentsAnalyzed(data.totalAnalyzedCount));
    dispatch(
      setModelStatistics(
        data.licenceStatistics.map((ls) => getModelStatisticsFromData(ls, getModelName))
      )
    );
    dispatch(setPercentageUsed(Math.round((data.totalAnalyzedCount / data.commentLimit) * 100)));

    return periodId;
  }
);
