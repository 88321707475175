import React from "react";
import styled, { css } from "styled-components";

import { useAppSelector } from "store";

import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { WidgetType } from "@explorance/mly-types";

type Props = {
  fadeIn: boolean;
  insight: string;
};

export const MostCriticalInsights = ({ fadeIn, insight }: Props) => {
  const state = useAppSelector((state) => state.summary);

  const mostCriticalItemsTypes = [
    {
      type: WidgetType.PrioritizedRecommendations,
      label: <Text resource="summary.header.mostCriticalInsights.recommendations" />,
    },
    {
      type: WidgetType.WhatToImprove,
      label: <Text resource="summary.header.mostCriticalInsights.improvement" />,
    },
    {
      type: WidgetType.MostPositive,
      label: <Text resource="summary.header.mostCriticalInsights.strengths" />,
    },
  ];

  const findCriticalInsight = () => {
    if (!state.summaryOverview) return;
    return mostCriticalItemsTypes.find((item) => item.type === insight);
  };

  return (
    <StyledInsightsContainer fadeIn={fadeIn}>
      <StyledHeader>
        <StyledIconContainer>
          <Icon type={IconType.mostNegativeTopics} color={Color.gray50} size={18} />
        </StyledIconContainer>
        <StyledInsightsTitle>
          <Text resource="summary.header.mostCriticalInsights.title" />
          <StyledNlpType>{findCriticalInsight()?.label}</StyledNlpType>
        </StyledInsightsTitle>
      </StyledHeader>

      {state.summaryOverview?.[insight].map((insight, index) => {
        return (
          <StyledCriticalInsightContainer key={`insight-#${index}`}>
            {findCriticalInsight()?.type === WidgetType.PrioritizedRecommendations && (
              <StyledAttributeContainer>
                <StyledAttributeIconContainer>
                  <Icon type={IconType[insight.type]} color={Color.gray50} size={9} />
                </StyledAttributeIconContainer>
                <StyledAttributeText>
                  <Text resource={`statTab.${insight.type}`} />
                </StyledAttributeText>
              </StyledAttributeContainer>
            )}
            {insight.topic && <StyledTopicPathContainer>{insight.topic}</StyledTopicPathContainer>}
            <StyledCriticalSummary>{insight.content}</StyledCriticalSummary>
          </StyledCriticalInsightContainer>
        );
      })}
    </StyledInsightsContainer>
  );
};

const StyledInsightsContainer = styled.div<{ fadeIn: boolean }>`
  border: 1px solid ${Color.indigo40};
  border-radius: 12px;
  padding: 24px 32px;
  ${({ fadeIn }) =>
    fadeIn &&
    css`
      animation: fadeIn 1s;
    `};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledHeader = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledInsightsTitle = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  font-weight: bold;
`;

const StyledNlpType = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: ${Color.gray50};
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  background-color: ${Color.sky20};
`;

const StyledCriticalInsightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 10px;
`;

const StyledAttributeContainer = styled.div`
  display: flex;
  gap: 5px;
  padding: 3px 0;
`;

const StyledAttributeIconContainer = styled.div`
  width: 18px;
  height: 19.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.sky30};
  border-radius: 5px;
`;

const StyledAttributeText = styled.div`
  font-weight: bold;
  color: ${Color.gray50};
`;

const StyledTopicPathContainer = styled.div`
  width: fit-content;
  background-color: ${Color.sky30};
  padding: 3px 10px 3px 6px;
  border-radius: 3px;
  font-size: 12px;
  @media print {
    border: 1px solid ${Color.indigo40};
  }
`;

const StyledCriticalSummary = styled.div`
  font-size: 15px;
`;
