import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { routes } from "routes";

import { deleteImportedFileById } from "services/files";

import { analysisJobInProgress } from "utils/analysisJobInProgress";
import { isAnalyzed } from "utils/isAnalyzed";
import { getLocalTime } from "utils/getLocalTime";
import { useTimeStamp } from "hooks/useTimeStamp";

import { useAppDispatch, useAppSelector } from "store";
import { showToastError } from "store/toast/toastSlice";
import {
  setImportModalAnalysisName,
  setImportModalErrorFallbackAnalysisId,
  setImportModalFileId,
  setImportModalOpen,
  setIsProcessingFinalStep,
} from "store/analysisSettings/dataSourceSlice";

import { Text } from "components/Text";
import { LoadingDots } from "components/LoadingDots";
import { Button } from "components/_buttons/Button";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";

import { RecentAnalysis } from "ts/analysis";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { AnalysisStatus, FileUploadStatus } from "@explorance/mly-types";
import { useRecentUpdateProgressBar } from "hooks/useRecentUpdateProgressBar";
import { getDefaultAnalysisPage } from "utils/getDefaultAnalysisPage";

type Props = {
  analysis: RecentAnalysis;
  refetchTableData: () => void;
};

export const RecentUpdateRow = ({ analysis, refetchTableData }: Props) => {
  const settingsState = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const recentActivitiesProgressBar = useRecentUpdateProgressBar({
    fileId: analysis.fileId,
    fileUploadStatus: analysis.fileUploadStatus,
    analysisStatus: analysis.analysisStatus,
    analysisId: analysis.analysisId,
  });

  const [hoveredAnalysisTitleId, setHoveredAnalysisTitleId] = useState<number>();

  const history = useHistory();
  const getTimeStamp = useTimeStamp();

  const analysisFailed = analysis.analysisStatus === AnalysisStatus.Failed;
  const firstStepFailed = analysis.fileUploadStatus === FileUploadStatus.ImportUploadError;
  const secondStepFailed = analysis.fileUploadStatus === FileUploadStatus.ImportValidationError;
  const importFailed =
    analysis.fileUploadStatus === FileUploadStatus.Failed || !analysis.fileUploadStatus;
  const importMoreDataFailed =
    firstStepFailed && analysis.analysisStatus === AnalysisStatus.Completed;
  const analysisFailedFirst =
    analysisFailed && analysis.analysisLastUpdated >= analysis.fileUploadLastUpdated;
  const importFailedFirst =
    importFailed &&
    (analysis.fileUploadLastUpdated >= analysis.analysisLastUpdated ||
      (!analysis.fileUploadLastUpdated && !analysis.sharedWithMe));
  const firstStepFailedFirst =
    firstStepFailed && analysis.fileUploadLastUpdated >= analysis.analysisLastUpdated;
  const secondStepFailedFirst =
    secondStepFailed && analysis.fileUploadLastUpdated >= analysis.analysisLastUpdated;
  const convertedLastUpdated = getLocalTime(new Date(analysis.lastUpdated));

  const hasSettingsAccess =
    (analysis.fileUploadStatus !== FileUploadStatus.Completed &&
      analysis.analysisStatus === AnalysisStatus.NotAnalyzed) ||
    [AnalysisStatus.Failed, AnalysisStatus.InProgress].includes(analysis.analysisStatus) ||
    importFailedFirst;

  const onHoverTitle = (analysis: RecentAnalysis) => (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
      setHoveredAnalysisTitleId(analysis.analysisId);
    }
  };

  const onClickRow = async (analysis: RecentAnalysis) => {
    const showImportModal =
      [AnalysisStatus.NotAnalyzed].includes(analysis.analysisStatus) &&
      analysis.fileUploadStatus !== FileUploadStatus.Completed &&
      analysis.fileId !== null;

    if (showImportModal) {
      if (analysis.fileUploadStatus === FileUploadStatus.Finalizing) {
        dispatch(setIsProcessingFinalStep(true));
      }
      dispatch(setImportModalOpen(true));
      dispatch(setImportModalFileId(analysis.fileId));
      dispatch(setImportModalAnalysisName(analysis.name));
      dispatch(setImportModalErrorFallbackAnalysisId(analysis.analysisId));
    } else if (
      ((hasSettingsAccess && !importMoreDataFailed) ||
        analysis.analysisStatus !== AnalysisStatus.Completed) &&
      !analysis.sharedWithMe
    ) {
      history.push(routes.settingsPage(analysis.analysisId));
    } else {
      history.push(await getDefaultAnalysisPage(analysis.analysisId, settingsState.features.llm));
    }
  };

  const handleDeleteFailedImport = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      if (importMoreDataFailed || firstStepFailed || importFailedFirst) {
        await deleteImportedFileById(analysis.fileId);
        refetchTableData();
      }
    } catch {
      dispatch(showToastError("toast.defaultError"));
    }
  };

  return (
    <>
      <StyledItem
        className="fade-in"
        onClick={() => onClickRow(analysis)}
        key={analysis.analysisId}
      >
        <StyledIconContainer>
          {analysisJobInProgress(analysis) ? (
            <LoadingDots scaleSize={0.8} />
          ) : isAnalyzed(analysis.analysisStatus) ? (
            <CategorizationTypeSymbol categorizationType={analysis.categorizationType} />
          ) : (
            <Icon type={IconType.import} color={Color.gray50} size={12} />
          )}
        </StyledIconContainer>
        <StyledAnalysisInfoContainer>
          <StyledAnalysisName
            onMouseOver={onHoverTitle(analysis)}
            onMouseOut={() => setHoveredAnalysisTitleId(null)}
            data-tooltip-id={String(analysis.analysisId)}
          >
            {analysis.name}
          </StyledAnalysisName>
          <StyledAnalysisDescription>
            <span>{getTimeStamp(convertedLastUpdated)}</span>
            {analysis.fileUploadStatus === FileUploadStatus.WaitingForInput ? (
              <span>
                {" • "}
                <Text resource="recentUpdatesTable.status.waitingForInput" />
              </span>
            ) : analysis.analysisStatus === AnalysisStatus.NotAnalyzed &&
              analysis.fileUploadStatus === FileUploadStatus.Uploading ? (
              <span>
                {" • "}
                <b style={{ color: Color.gray50 }}>
                  <Text resource="recentUpdatesTable.status.import.step1" />
                </b>
              </span>
            ) : analysis.fileUploadStatus === FileUploadStatus.Finalizing ? (
              <span>
                {" • "}
                <b style={{ color: Color.gray50 }}>
                  <Text resource="recentUpdatesTable.status.import.step2" />
                </b>
              </span>
            ) : (
              analysis.analysisStatus === AnalysisStatus.NotAnalyzed &&
              !importFailedFirst &&
              !firstStepFailedFirst &&
              !secondStepFailedFirst && (
                <span>
                  {" • "}
                  <Text resource="recentUpdatesTable.status.notAnalyzedYet" />
                </span>
              )
            )}

            {analysis.analysisStatus === AnalysisStatus.InProgress && (
              <span>
                {" • "}
                <Text resource="recentUpdatesTable.status.analyzing" />
              </span>
            )}
            {analysis.analysisStatus === AnalysisStatus.Completed && !analysis.sharedWithMe ? (
              <span>
                {" • "}
                <Text
                  resource={{
                    key: "recentUpdatesTable.analyzedCommentsCount",
                    args: [`${analysis.analyzedCommentCount}`],
                  }}
                />
              </span>
            ) : (
              analysis.analysisStatus === AnalysisStatus.Completed && (
                <span>
                  {" • "}
                  <Text resource="recentUpdatedTable.sharedWithMe" />
                </span>
              )
            )}
            {(analysisFailedFirst || analysisFailed) && (
              <span>
                {" • "}
                <StyledFailedSpan>
                  <Text resource="table.rowStatus.analysisFailed" />
                </StyledFailedSpan>
              </span>
            )}
            {importFailedFirst && (
              <span>
                {" • "}
                <StyledFailedSpan>
                  <Text resource="table.rowStatus.importFailed" />
                </StyledFailedSpan>
              </span>
            )}
            {(firstStepFailedFirst || firstStepFailed) && (
              <span>
                {" • "}
                <StyledFailedSpan>
                  <Text resource="table.rowStatus.firstStepFailed" />
                </StyledFailedSpan>
              </span>
            )}
            {secondStepFailedFirst && (
              <span>
                {" • "}
                <StyledFailedSpan>
                  <Text resource="table.rowStatus.secondStepFailed" />
                </StyledFailedSpan>
              </span>
            )}
          </StyledAnalysisDescription>
          {(analysis.fileUploadStatus === FileUploadStatus.Uploading ||
            analysis.analysisStatus === AnalysisStatus.InProgress ||
            analysis.fileUploadStatus === FileUploadStatus.Finalizing) && (
            <StyledProgressBarContainer>
              <StyledProgressBar
                progressPercentage={
                  analysis.fileUploadStatus === FileUploadStatus.Uploading && analysis.fileId
                    ? recentActivitiesProgressBar.uploadProgress
                    : analysis.fileUploadStatus === FileUploadStatus.Finalizing
                    ? recentActivitiesProgressBar.validationProgress
                    : recentActivitiesProgressBar.analysisProgress
                }
              />
            </StyledProgressBarContainer>
          )}
          {hoveredAnalysisTitleId && (
            <Tooltip
              isOpen={!!hoveredAnalysisTitleId}
              tooltipId={analysis.analysisId.toString()}
              className="tooltip-file-title"
              content={analysis.name}
            />
          )}
        </StyledAnalysisInfoContainer>

        <StyledButtonOverlayContainer>
          <Button variant={ButtonVariant.outlineBlue} size={ButtonSize.sm}>
            <Text
              resource={
                hasSettingsAccess && !importMoreDataFailed
                  ? "analysisList.table.dropdown.viewSource"
                  : analysis.analysisStatus !== AnalysisStatus.Completed
                  ? "analysisList.table.dropdown.viewSettings"
                  : "analysisList.table.dropdown.viewAnalysis"
              }
            />
          </Button>
          {((importFailedFirst && analysis.fileUploadStatus !== null) ||
            importMoreDataFailed ||
            firstStepFailedFirst ||
            firstStepFailed) && (
            <StyledImportFailedActionsContainer>
              <StyledDeleteButton onClick={(e) => handleDeleteFailedImport(e)}>
                <Icon type={IconType.trash} size={14} style={{ marginLeft: 10 }} />
              </StyledDeleteButton>
            </StyledImportFailedActionsContainer>
          )}
        </StyledButtonOverlayContainer>
      </StyledItem>
    </>
  );
};

const StyledButtonOverlayContainer = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  padding-left: 12px;
  background-color: ${Color.white};
  display: flex;
  opacity: 0;
  transition: 0.13s opacity;
  align-items: center;
  justify-content: center;
`;

const StyledImportFailedActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDeleteButton = styled.button`
  border: none;
  background: none;
`;

const StyledFailedSpan = styled.span`
  color: ${Color.red40};
  font-weight: bold;
`;

const StyledAnalysisInfoContainer = styled.div`
  min-width: 0;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -12px;
`;
const StyledItem = styled.div`
  &:hover ${StyledButtonOverlayContainer} {
    opacity: 1;
  }
  &:last-child {
    border-bottom: none;
  }

  position: relative;
  display: grid;
  grid-template-columns: 1fr 5fr;
  transition: background-color 300ms ease-out;

  font-size: 0.875em;
  border-bottom: 1px solid ${Color.sky40};
  padding: 10px;

  > * {
    min-width: 0;
    min-height: 0;
  }

  .failed {
    color: ${Color.red30};
  }

  .tooltip-file-title {
    max-width: 300px;
    overflow-wrap: break-word;
  }

  &:hover {
    cursor: pointer;
    background-color: ${Color.sky10};
  }
`;

const StyledAnalysisName = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.gray40};
  margin-bottom: 2px;
`;

const StyledAnalysisDescription = styled.div`
  color: ${Color.gray30};
  font-size: 14px;
`;

const StyledProgressBarContainer = styled.div`
  margin-top: 5px;
  background-color: ${Color.blue20};
  height: 5px;
`;

const StyledProgressBar = styled.div<{ progressPercentage: number }>`
  background-color: ${Color.green50};
  width: ${({ progressPercentage }) => (progressPercentage ? progressPercentage : 0)}%;
  height: 5px;
  transition: width 0.8s ease-in-out;
`;
