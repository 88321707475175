import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { fetchAllAnalysisFilesSummary, fetchFileSummary } from "./thunks";
import { FileSummary, UpdatedFileDemographicParams } from "ts/file";
import {
  DateFormat,
  DemographicTypes,
  ErrorType,
  FileUploadStatus,
  ImportMode,
} from "@explorance/mly-types";

type UpdateDemographicFieldPayload = {
  demographicId: number;
  name?: string;
  selectedType?: DemographicTypes;
  dateFormat?: DateFormat;
};

export type DataSourceErrorDetails = {
  badValue?: string;
  column?: string;
  expectedFormat?: string;
  expectedType?: string;
  isValid?: boolean;
  rowNum?: number;
  selectedDateFormat?: DateFormat;
  ErrorHeaderValue?: string;
  ErrorColumn?: string;
  MaxAnalysisRows?: string;
  MaxColumnNumber?: string;
  MaxHeaderLength?: string;
  details?: string;
};

type DataSourceError = {
  type: ErrorType;
  details: DataSourceErrorDetails;
};

type DataSourceState = {
  analysisFileSummary: FileSummary;
  suggestedUniqueColumns: string[];
  isProcessingFinalStep: boolean;
  error: DataSourceError;

  // importModal
  importModalOpen: boolean;
  importModalFileId: number;
  importModalAnalysisId: number;
  importModalErrorFallbackAnalysisId: number;
  importModalAnalysisName: string;
  hasImportError: boolean;
  validationProgressPercentage: number;
  importProgressPercentage: number;
  loadingReimport: boolean;
  isImportMoreData: boolean;
  fileUploadStatusFromFileFetch: FileUploadStatus;
  fileValidationPercentage: number;

  savedHintIndex: number;
  // inputs
  selectedImportType: ImportMode;
  selectedUniqueColumn: string;
  updatedDemographicData: UpdatedFileDemographicParams[];
};

const initialState: DataSourceState = {
  analysisFileSummary: null,
  suggestedUniqueColumns: null,
  isProcessingFinalStep: false,
  error: null,
  hasImportError: false,
  importModalOpen: false,
  importModalFileId: null,
  importModalAnalysisId: null,
  importModalErrorFallbackAnalysisId: null,
  importModalAnalysisName: "",
  isImportMoreData: false,
  loadingReimport: false,
  selectedImportType: ImportMode.AppendAndUpdate,
  selectedUniqueColumn: null,
  updatedDemographicData: null,
  validationProgressPercentage: 0,
  importProgressPercentage: 0,
  fileUploadStatusFromFileFetch: null,
  savedHintIndex: 0,
  fileValidationPercentage: 0,
};

const dataSourceSlice = createSlice({
  name: "dataSource",
  initialState,
  reducers: {
    setAnalysisFileSummary: (state, action: PayloadAction<FileSummary>) => {
      state.analysisFileSummary = action.payload;
    },
    setSelectedImportType: (state, action: PayloadAction<ImportMode>) => {
      state.selectedImportType = action.payload;
    },
    setSelectedUniqueColumn: (state, action: PayloadAction<string>) => {
      state.selectedUniqueColumn = action.payload;
    },
    setIsProcessingFinalStep: (state, action: PayloadAction<boolean>) => {
      state.isProcessingFinalStep = action.payload;
    },
    setImportModalOpen: (state, action: PayloadAction<boolean>) => {
      state.importModalOpen = action.payload;
    },
    setIsImportMoreData: (state, action: PayloadAction<boolean>) => {
      state.isImportMoreData = action.payload;
    },
    setImportModalFileId: (state, action: PayloadAction<number>) => {
      state.importModalFileId = action.payload;
      state.importModalAnalysisId = null;
    },
    setImportModalAnalysisId: (state, action: PayloadAction<number>) => {
      state.importModalAnalysisId = action.payload;
      state.importModalFileId = null;
    },
    setImportModalErrorFallbackAnalysisId: (state, action: PayloadAction<number>) => {
      state.importModalErrorFallbackAnalysisId = action.payload;
    },
    setHasImportError: (state, action: PayloadAction<boolean>) => {
      state.hasImportError = action.payload;
    },
    setImportModalAnalysisName: (state, action: PayloadAction<string>) => {
      state.importModalAnalysisName = action.payload;
    },
    setLoadingReimport: (state, action: PayloadAction<boolean>) => {
      state.loadingReimport = action.payload;
    },

    setValidationProgressPercentage: (state, action: PayloadAction<number>) => {
      state.validationProgressPercentage = action.payload;
    },
    setImportProgressPercentage: (state, action: PayloadAction<number>) => {
      state.importProgressPercentage = action.payload;
    },
    setFileUploadStatusFromFileFetch: (state, action: PayloadAction<FileUploadStatus>) => {
      state.fileUploadStatusFromFileFetch = action.payload;
    },
    setSavedHintIndex: (state, action: PayloadAction<number>) => {
      state.savedHintIndex = action.payload;
    },
    handleImportValidationError: (state, action: PayloadAction<DataSourceError>) => {
      state.error = action.payload;
      state.isProcessingFinalStep = false;
    },
    updateDemographicField: (state, action: PayloadAction<UpdateDemographicFieldPayload>) => {
      const { demographicId, ...updatedDemographicProperties } = action.payload;
      const demographicIndex = state.updatedDemographicData.findIndex(
        (d) => d.id === demographicId
      );

      if (demographicIndex !== -1) {
        const newDemographic = cloneDeep({
          ...state.updatedDemographicData[demographicIndex],
          ...updatedDemographicProperties,
        });

        if (newDemographic.selectedType !== DemographicTypes.Date) {
          delete newDemographic.dateFormat;
        }
        state.updatedDemographicData[demographicIndex] = newDemographic;
      }

      // Reset error only if the field that had an error is being updated
      if (state.error?.details.column === state.updatedDemographicData[demographicIndex].name) {
        state.error = null;
      }
    },
    resetDataSource: (state) => (state = { ...initialState, savedHintIndex: state.savedHintIndex }),
  },
  extraReducers: (builder) => {
    // GET files/:id case
    builder.addCase(fetchFileSummary.fulfilled, (state, action) => {
      state.fileUploadStatusFromFileFetch = action.payload.uploadStatus;
      if (action.payload.uploadStatus === FileUploadStatus.Uploading) return;
      if (action.payload.progress) {
        state.fileValidationPercentage = action.payload.progress;
      }
      state.analysisFileSummary = {
        demographics: action.payload.demographics,
        comments: action.payload.comments,
        rowCount: action.payload.rowCount,
      };
      state.suggestedUniqueColumns = action.payload.suggestedUniqueColumns;
      state.selectedUniqueColumn = action.payload.suggestedUniqueColumns?.[0];

      if (!state.updatedDemographicData) {
        state.updatedDemographicData = action.payload.demographics?.map((d) => ({
          id: d.id,
          selectedType: d.selectedType || d.suggestedTypes[0],
          dateFormat: d.dateFormat,
          name: d.name,
        }));
      }

      // Handle errors
      // All error payloads documented in GET /files/:id confluence article
      if (
        ![
          FileUploadStatus.ImportValidationError,
          FileUploadStatus.ImportUploadError,
          FileUploadStatus.Failed,
        ].includes(action.payload.uploadStatus)
      ) {
        state.error = null;
        return;
      }

      // demographic type error
      if (action.payload.uploadStatus === FileUploadStatus.ImportValidationError) {
        const wrongDateFormat = action.payload.errorDetails.expectedFormat as DateFormat;
        state.isProcessingFinalStep = false;
        state.error = {
          type: ErrorType.DemographicInvalidDateFormat,
          details: {
            ...action.payload.errorDetails,
            selectedDateFormat: wrongDateFormat,
          },
        };
        state.updatedDemographicData = state.updatedDemographicData.map((d) => {
          if (d.name !== action.payload.errorDetails.column) return d;
          if (action.payload.errorDetails.expectedType === DemographicTypes.Date) {
            return {
              ...d,
              selectedType: DemographicTypes.Date,
              dateFormat: wrongDateFormat,
            };
          }
          if (action.payload.errorDetails.expectedType === DemographicTypes.Number) {
            return {
              ...d,
              selectedType: DemographicTypes.Number,
            };
          }
        });
      }
    });
    // GET analysis/:id/files case
    builder.addCase(fetchAllAnalysisFilesSummary.pending, (state) => {
      state.selectedImportType = null;
    });
    builder.addCase(fetchAllAnalysisFilesSummary.fulfilled, (state, action) => {
      state.analysisFileSummary = {
        demographics: action.payload.demographics,
        comments: action.payload.comments,
        rowCount: action.payload.rowCount,
      };
      state.suggestedUniqueColumns = null;
      state.selectedUniqueColumn = null;
      state.selectedImportType = null;
      state.updatedDemographicData = action.payload.demographics?.map((d) => ({
        id: d.id,
        selectedType: d.selectedType || d.suggestedTypes[0],
        dateFormat: d.dateFormat,
        name: d.name,
      }));
    });
  },
});

export const {
  setAnalysisFileSummary,
  resetDataSource,
  setIsProcessingFinalStep,
  setSelectedImportType,
  setSelectedUniqueColumn,
  handleImportValidationError,
  updateDemographicField,
  setImportModalOpen,
  setImportModalFileId,
  setImportModalAnalysisId,
  setImportModalErrorFallbackAnalysisId,
  setImportModalAnalysisName,
  setHasImportError,
  setValidationProgressPercentage,
  setLoadingReimport,
  setImportProgressPercentage,
  setIsImportMoreData,
  setFileUploadStatusFromFileFetch,

  setSavedHintIndex,
} = dataSourceSlice.actions;

export default dataSourceSlice.reducer;
