import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSummaryOverview } from "services/analysis";
import { AppDispatch } from "store";
import { SummaryType } from "ts/summary";
import { setIsCachedResponse } from "./summarySlice";

type FetchSummaryOverviewParams = {
  analysisId: number;
  cancelToken: any;
  user_id?: number;
  sharing_id?: number;
};

export const fetchSummaryOverview = createAsyncThunk<
  SummaryType,
  FetchSummaryOverviewParams,
  { dispatch: AppDispatch }
>(
  "summary/fetchSummaryOverview",
  async ({ analysisId, user_id, cancelToken, sharing_id }, thunkAPI): Promise<any> => {
    try {
      const res = await getSummaryOverview(analysisId, cancelToken, user_id, sharing_id);

      if (res.headers["request-duration"] <= 2000) {
        thunkAPI.dispatch(setIsCachedResponse(true));
      }

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
