import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store";
import { fetchFileSummary } from "store/analysisSettings/thunks";
import { getFileById } from "services/files";
import { getAnalysisStatus } from "services/analysis";

import { BE_IMPORT_TASK_PROGRESS_MULTIPLIER } from "assets/constants/import";
import { AnalysisStatus, FileUploadStatus, SocketEvent } from "@explorance/mly-types";

type Props = {
  fileId?: number;
  fileUploadStatus?: FileUploadStatus;
  analysisStatus?: AnalysisStatus;
  analysisId?: number;
};

export const useRecentUpdateProgressBar = ({
  fileId,
  fileUploadStatus,
  analysisStatus,
  analysisId,
}: Props) => {
  const socket = useAppSelector((state) => state.wsStream.socket);
  const modalState = useAppSelector((state) => state.dataSource);
  const dispatch = useAppDispatch();

  const [analysisProgressPercentage, setAnalysisProgressPercentage] = useState<number>(0);
  const [analysisBuffer, setAnalysisBuffer] = useState<number>(0);
  const [analysisBufferProgress, setAnalysisBufferProgress] = useState<number>(0);

  const [uploadFileProgress, setFileUploadProgress] = useState<number>(0);
  const [uploadFileBuffer, setUploadFileBuffer] = useState<number>(0);
  const [uploadBufferProgress, setUploadBufferProgress] = useState<number>(0);

  const [validationProgressPercentage, setValidationProgressPercentage] = useState<number>(0);
  const [validationBuffer, setValidationBuffer] = useState<number>(0);
  const [validationBufferProgress, setValidationBufferProgress] = useState<number>(0);

  // File validation effects
  useEffect(() => {
    if (validationProgressPercentage > validationBufferProgress && validationBuffer > 0) {
      const timeoutId = setTimeout(() => {
        setValidationBufferProgress((prev) => prev + validationBuffer);
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [validationBuffer, validationBufferProgress]); //eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    socket.on(SocketEvent.ChangedImportProgressBar, (data) => {
      setValidationProgressPercentage(data.percentage);
      setValidationBuffer((data.percentage - validationBufferProgress) / 10);
    });
    return () => {
      socket.off(SocketEvent.ChangedImportProgressBar, (data) =>
        setValidationProgressPercentage(data.percentage)
      );
    };
  }, [socket]); //eslint-disable-line

  useEffect(() => {
    if (fileUploadStatus === FileUploadStatus.Finalizing) {
      dispatch(fetchFileSummary(fileId)).then((data: any) => {
        setValidationProgressPercentage(
          data.payload?.uploadStatus === FileUploadStatus.Completed ? 95 : data.payload?.progress
        );
        setValidationBuffer(
          ((data.payload?.uploadStatus === FileUploadStatus.Completed
            ? 95
            : data.payload?.progress) -
            validationBufferProgress) /
            10
        );
      });
    }
  }, []); //eslint-disable-line

  // File upload effects
  useEffect(() => {
    if (uploadFileProgress > uploadBufferProgress + uploadFileBuffer && uploadFileBuffer > 0) {
      const timeoutId = setTimeout(() => {
        setUploadBufferProgress((prev) => prev + uploadFileBuffer);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [uploadFileBuffer, uploadBufferProgress]); // eslint-disable-line

  useEffect(() => {
    if (!fileId || fileUploadStatus !== FileUploadStatus.Uploading) return;

    getFileById(fileId)
      .then((res) => {
        setUploadBufferProgress(res.data.progress * BE_IMPORT_TASK_PROGRESS_MULTIPLIER);
        setUploadFileBuffer(
          (res.data.progress * BE_IMPORT_TASK_PROGRESS_MULTIPLIER - uploadBufferProgress) / 10
        );
      })
      .catch((err) => console.error(err));

    const polling = () => {
      const timer = setInterval(() => {
        getFileById(fileId)
          .then((res) => {
            if (
              res.data.uploadStatus === FileUploadStatus.WaitingForInput ||
              res.data.uploadStatus === FileUploadStatus.ImportUploadError ||
              res.data.uploadStatus === FileUploadStatus.Failed
            ) {
              clearInterval(timer);
              return;
            } else {
              setFileUploadProgress(res.data.progress * BE_IMPORT_TASK_PROGRESS_MULTIPLIER);
              setUploadFileBuffer(
                (res.data.progress * BE_IMPORT_TASK_PROGRESS_MULTIPLIER - uploadBufferProgress) / 10
              );
            }
          })
          .catch((err) => console.error(err));
      }, 2000);
    };
    polling();
  }, [fileId]); // eslint-disable-line

  //  Analysis effects
  useEffect(() => {
    if (
      analysisProgressPercentage > analysisBufferProgress + analysisBuffer &&
      analysisBuffer > 0
    ) {
      const timeoutId = setTimeout(() => {
        setAnalysisBufferProgress((prev) => prev + analysisBuffer);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [analysisProgressPercentage, analysisBufferProgress]); // eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    socket.on(SocketEvent.ChangedAnalysisProgressBar, (data) => {
      if (modalState.importModalAnalysisId !== data.analysisId) return;
      setAnalysisProgressPercentage(data.percentage);
      setAnalysisBuffer((data.percentage - analysisBufferProgress) / 10);
    });

    return () => {
      socket.off(SocketEvent.ChangedAnalysisProgressBar, (data) => {
        setAnalysisProgressPercentage(data.percentage);
        setAnalysisBuffer((data.percentage - analysisBuffer) / 10);
      });
    };
  }, [socket]); // eslint-disable-line

  useEffect(() => {
    if (analysisStatus !== AnalysisStatus.InProgress) return;

    getAnalysisStatus(analysisId).then((data) => {
      if (data.data.status === AnalysisStatus.InProgress) {
        setAnalysisBuffer((data.data.percentage - analysisBuffer) / 10);
        setAnalysisBufferProgress(data.data.percentage);
      }
    });
  }, []); // eslint-disable-line

  return {
    uploadProgress: uploadBufferProgress,
    analysisProgress: analysisBufferProgress,
    validationProgress: validationBufferProgress,
  };
};
