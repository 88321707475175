import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AnalysisContext } from "context/AnalysisContext";
import { useQueryParams } from "hooks/useQueryParams";

import { getRelevantComments, getTopicStatisticsByAnalysisId } from "services/analysis";
import { getInitialStat, getModalStyles, statBuilder } from "./helpers";
import { getCustomModelId } from "utils/getCustomModelId";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { Modal } from "../Modal";
import { TopicViewToggle } from "components/TopicViewToggle";
import { Button } from "components/_buttons/Button";
import { Banner } from "./Banner";
import { StatSection } from "./StatSection";
import { TopComments } from "./TopComments";
import { StackedBarChart } from "components/StackedBarChart";
import { Text } from "components/Text";
import { Skeleton } from "components/Skeleton";
import { TopCommentsPlaceholder } from "./Placeholder";
import { TopicsListView, StatisticsType, DemographicFilterMethod } from "@explorance/mly-types";

import { ButtonVariant } from "ts/enums/button";
import { TopicModalData } from "ts/topic";
import { StackedBarChartCount } from "ts/overview";
import { Color } from "ts/enums/color";

const MAX_COMMENT_LIMIT = 20;

export type Demographic = {
  id: number;
  name: string;
  method: DemographicFilterMethod;
  values: string[];
};

type Filters = {
  demographics: Demographic[];
  threshold?: number;
  selectedColumns?: string[];
  categories?: string[];
};

type Props = {
  isOpen: boolean;
  isVirtual: boolean;
  topic: TopicModalData;
  filters: Filters;
  showToggle?: boolean;
  selectedView?: TopicsListView;
  showSentimentFeatures?: boolean;
  onClose: () => void;
};

export const RelevantCommentsModal = ({
  topic,
  isOpen,
  showToggle,
  selectedView,
  filters,
  showSentimentFeatures,
  isVirtual,
  onClose,
}: Props) => {
  const [state] = useContext(AnalysisContext);
  const analysisId = state.analysisDetails.id;

  const [view, setView] = useState<TopicsListView>(selectedView);
  const [updatedTopic, setUpdatedTopic] = useState<TopicModalData>(topic);
  const [selectedStat, setSelectedStat] = useState<StatisticsType>(
    getInitialStat(topic, selectedView)
  );
  const [barChartPieces, setBarChartPieces] = useState<StackedBarChartCount[]>(
    statBuilder(topic, view)
  );

  const [commentLimit, setCommentLimit] = useState<number>(5);
  const [relevantComments, setRelevantComments] = useState<string[]>([]);
  const [activeBarIndex, setActiveBarIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const customModelId = isVirtual ? getCustomModelId(state) : null;
  const { sharingId } = useQueryParams();

  const topicPath = topic.fullPath;

  const barClickHandler = (index: number) => {
    setIsLoading(true);
    setActiveBarIndex(index);
    setSelectedStat(StatisticsType[barChartPieces[index].attribute]);
  };

  const changeView = (seletedView: TopicsListView) => {
    setIsLoading(true);
    setView(seletedView);
  };

  useEffect(() => {
    if (!isOpen) return;
    getTopicStatisticsByAnalysisId(
      analysisId,
      getUrlQueryString({
        custom_model_id: customModelId,
        view,
        user_id: state.previewUser.id,
        sharing_id: sharingId,
      }),
      {
        topics: [topicPath],
        demographics: filters.demographics,
        threshold: filters.threshold,
        ...(filters?.selectedColumns?.length && { selectedColumns: filters.selectedColumns }),
      }
    )
      .then((tableDataResponse) => {
        setUpdatedTopic(tableDataResponse.data.topics[0]);
      })
      .catch((err: any) => console.error(err.message));
  }, [view, state, isOpen, customModelId, analysisId, sharingId, topicPath, filters]); // eslint-disable-line

  useEffect(() => {
    if (!isOpen) return;
    !isLoading && setIsLoading(true);
    const requestBody = {
      topics: [topicPath],
      demographics: filters.demographics,
      ...(filters?.selectedColumns?.length && { selectedColumns: filters.selectedColumns }),
      ...(filters?.categories?.length && { categories: filters.categories }),
    };
    if (selectedStat) {
      getRelevantComments({
        analysisId,
        statistic: selectedStat,
        requestBody,
        limit: MAX_COMMENT_LIMIT,
        custom_model_id: customModelId,
        sharing_id: sharingId,
        user_id: state.previewUser.id,
      })
        .then(({ data }) => {
          setRelevantComments(data.comments);
        })
        .catch((err) => console.error(err.message))
        .finally(() => setTimeout(() => setIsLoading(false), 200));
    }
  }, [analysisId, isOpen, selectedStat, topicPath, filters]); // eslint-disable-line

  useEffect(() => {
    if (!isOpen || !showSentimentFeatures) return;
    setSelectedStat(getInitialStat(updatedTopic, view));
    setBarChartPieces(statBuilder(updatedTopic, view));
  }, [view, updatedTopic, isOpen, showSentimentFeatures]);

  useEffect(() => {
    if (!isOpen || !showSentimentFeatures) return;
    if (barChartPieces?.length > 0 && selectedStat) {
      if (selectedStat !== StatisticsType[barChartPieces[activeBarIndex]?.attribute]) {
        const index = barChartPieces.findIndex(
          (p) => (p.attribute as unknown as StatisticsType) === selectedStat
        );
        setActiveBarIndex(index === -1 ? 0 : index);
      }
    }
  }, [barChartPieces, selectedStat, activeBarIndex, isOpen, showSentimentFeatures]);

  return (
    <Modal
      isOpen={isOpen}
      handleRequestCloseFunc={onClose}
      styles={getModalStyles(commentLimit, relevantComments.length)}
      overlayBgColor="rgba(53, 56, 77, .20)"
    >
      <StyledTopicSummaryContainer>
        <StyledHeaderContainer>
          <StyledHeader>
            <Text resource="modal.relevantTopics.title" />
          </StyledHeader>
          {showToggle && (
            <TopicViewToggle currentView={view} toggleView={changeView} isLoading={isLoading} />
          )}
        </StyledHeaderContainer>

        <Banner
          topic={updatedTopic}
          view={view}
          isLoading={isLoading}
          topicFullPath={topicPath}
          isVirtual={isVirtual}
          recommendationCount={topic?.recommendations}
        />

        <StatSection
          view={view}
          selectedStat={selectedStat}
          onChangeStat={setSelectedStat}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          showSentimentFeatures={showSentimentFeatures}
        />

        {showSentimentFeatures &&
          (isLoading ? (
            <Skeleton width={948} height={12} backgroundColor={Color.blue20} />
          ) : (
            <StyledBarChartContainer noCommentsAvailable={relevantComments.length === 0}>
              <StackedBarChart
                chartPieces={barChartPieces.map((c) => ({ portion: c.percentage, color: c.color }))}
                handleBarClick={barClickHandler}
                activeBarIndex={activeBarIndex}
                isClickable
              />
            </StyledBarChartContainer>
          ))}

        <StyledDivider />

        {isLoading ? (
          <TopCommentsPlaceholder />
        ) : (
          <TopComments
            relevantComments={relevantComments}
            view={view}
            commentLimit={commentLimit}
            updateCommentLimit={setCommentLimit}
          />
        )}

        <StyledFooter>
          <Button
            onClick={onClose}
            variant={ButtonVariant.light}
            style={{ height: "41px", width: "128px", padding: "0" }}
          >
            <Text resource="modal.relevantTopics.button.closeButton" />
          </Button>
        </StyledFooter>
      </StyledTopicSummaryContainer>
    </Modal>
  );
};

const StyledTopicSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`;

const StyledHeader = styled.div`
  font-size: 19px;
  font-weight: bold;
`;

const StyledDivider = styled.div`
  height: 2px;
  background-color: ${Color.neutral10};
  margin: 10px -25px;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: absolute;
  background-color: ${Color.white};
  width: 100%;
  z-index: 1;
  margin: 0 -25px;
  padding: 0 25px;
  bottom: 25px;
`;

const StyledBarChartContainer = styled.div<{ noCommentsAvailable: boolean }>`
  height: ${({ noCommentsAvailable }) => noCommentsAvailable && "12px"};
`;
