import React, { useState } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { UserDemographic } from "context/UserProfileContext";

import { Select } from "components/_inputs/Select";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { RedactionCustomCondition } from "ts/sharing";
import { Color } from "ts/enums/color";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { ButtonVariant } from "ts/enums/button";
import { useAppSelector } from "store";
import { isOneCustomConditionEmpty } from "utils/sharing";
import { useResource } from "hooks/useResource";
import { UserDemographicFilterMenu } from "../../UserDemographicFilterMenu";
import { voidFunc } from "utils/voidFunc";
import useClickOutside from "hooks/useClickOutside";
import { TextTruncator } from "components/TextTruncator";

type Props = {
  index: number;
  customConditionRedactions: RedactionCustomCondition;
  savedCustomConditions: RedactionCustomCondition[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  showRedactionCustomConditionError: boolean;
  updateRedactionCustomConditions: (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => void;
  handleHideRedactionCustomConditionError: () => void;
};

export const CustomRedactionCondition = ({
  index,
  customConditionRedactions,
  savedCustomConditions,
  userDemographics,
  showRedactionCustomConditionError,
  updateRedactionCustomConditions,
  handleHideRedactionCustomConditionError,
}: Props) => {
  const state = useAppSelector((state) => state.sharing);

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [localSelectedDemographics, setLocalSelectedDemographics] = useState<UserDemographic[]>([]);

  const popupRef = useClickOutside(() => setShowFilterDropdown(false));

  const { getResource } = useResource();

  const { userDemographicValues, redactionVersionId, userDemographic } = customConditionRedactions;

  const userDemographicHasError =
    showRedactionCustomConditionError &&
    !userDemographic &&
    (userDemographicValues.length > 0 || redactionVersionId);

  const userDemographicValuesHasError =
    showRedactionCustomConditionError &&
    userDemographicValues.length === 0 &&
    userDemographic &&
    redactionVersionId;

  const redactionVersionHasError =
    showRedactionCustomConditionError &&
    !redactionVersionId &&
    (userDemographicValues.length > 0 || userDemographic);

  const selectedRedactionVersion = state.redactionVersions.find(
    (version) => version.id === redactionVersionId
  );

  // functions
  const selectRedactionVersion = (id: string) => {
    const selectedVersion = state.redactionVersions?.find((version) => `${version.id}` === id);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].redactionVersionId = selectedVersion.id;
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const selectUserDemographicValuesOption = (demFilters: UserDemographic[]) => {
    const copy = cloneDeep(savedCustomConditions);
    copy[index].userDemographicValues = demFilters[0]?.values || [];
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const selectUserDemographicOption = (name: string) => {
    const copy = cloneDeep(savedCustomConditions);
    copy[index].userDemographic = name;
    copy[index].userDemographicValues = [];
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const deleteCondition = () => {
    const copy = cloneDeep(savedCustomConditions);
    copy.splice(index, 1);
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const openUserDemographicSelection = () => {
    setLocalSelectedDemographics([
      {
        name: state.allUserDemographics.find((demo) => demo.name === userDemographic).name,
        values: userDemographicValues,
      },
    ]);
    setShowFilterDropdown((prev) => !prev);
  };

  const validateConditions = (updatedRedactionCustomConditions: RedactionCustomCondition[]) => {
    if (showRedactionCustomConditionError) {
      const hasError = isOneCustomConditionEmpty(updatedRedactionCustomConditions);
      !hasError && handleHideRedactionCustomConditionError();
    }
  };

  return (
    <StyledCustomCondition>
      <span>
        <Text resource="sharing.redaction.customCondition.label" />
      </span>

      <StyledRedactionSelectContainer>
        <Select
          selectedOption={{
            label: selectedRedactionVersion?.name,
            value: `${selectedRedactionVersion?.id}`,
          }}
          options={state.redactionVersions?.map((version) => ({
            label: <TextTruncator value={version.name} />,
            value: version.id?.toString(),
          }))}
          handleChange={selectRedactionVersion}
          buttonStyle={{ width: "100%" }}
          selectStyle={{ width: "100%" }}
          placeholderKey="sharing.permissionForm.customCondition.defaultRedactionVersion.label"
          buttonVariant={redactionVersionHasError ? ButtonVariant.error : ButtonVariant.outlineGray}
        />
      </StyledRedactionSelectContainer>
      <Text resource="sharing.redaction.customCondition.label.ifUsers" />

      <Select
        selectedOption={{
          label: userDemographic,
          value: userDemographic,
        }}
        options={userDemographics.map((userDem) => ({
          label: <TextTruncator value={userDem.name} />,
          value: userDem.name,
        }))}
        handleChange={selectUserDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ borderRadius: "2px", width: "100%" }}
        buttonVariant={userDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray}
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultUserDemographic.label"
      />

      <span>
        <Text resource="sharing.redaction.customCondition.label.equalsTo" />
      </span>

      <StyledUserDemographicSelection ref={popupRef}>
        <Select
          options={[]}
          selectedOption={{
            label:
              userDemographicValues.length > 0 ? (
                <TextTruncator value={userDemographicValues.join(", ")} />
              ) : null,
            value: null,
          }}
          handleChange={voidFunc}
          buttonVariant={
            userDemographicValuesHasError ? ButtonVariant.error : ButtonVariant.outlineGray
          }
          buttonStyle={{ width: "100%" }}
          onClick={openUserDemographicSelection}
          disabled={!userDemographic}
          placeholderKey="sharing.permissionForm.customCondition.defaultUserDemographic.label"
        />

        {showFilterDropdown && (
          <UserDemographicFilterMenu
            allFiltersList={state.allUserDemographics.filter(
              (demo) => demo.name === userDemographic
            )}
            selectedFilter={state.allUserDemographics.find((demo) => demo.name === userDemographic)}
            currentlySelectedFilters={localSelectedDemographics}
            sectionTitle={getResource("userList.detailsPopup.title")}
            parentCheckboxLabel={getResource("columnFilterSection.selectAll")}
            margin={"36px 0px 0px 0px"}
            width="inherit"
            isMenuClosed={!showFilterDropdown}
            updateFilters={(filters) => setLocalSelectedDemographics(filters)}
            applyFilters={() => {
              selectUserDemographicValuesOption(localSelectedDemographics);
              setShowFilterDropdown(false);
            }}
          />
        )}
      </StyledUserDemographicSelection>

      <StyledDeleteContainer onClick={deleteCondition}>
        <Icon type={IconType.xCircle} color={Color.gray30} size={14} />
      </StyledDeleteContainer>
    </StyledCustomCondition>
  );
};

const StyledCustomCondition = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-size: 15px;
    color: ${Color.gray50};
  }

  /* setting the widths of Select dropdowns */
  @media (max-width: 1365px) {
    > div:nth-of-type(1) {
      width: 15%;
    }

    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      width: 21%;
    }
  }

  @media (min-width: 1366px) {
    > div:nth-of-type(1) {
      width: 20%;
    }
    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      width: 24%;
    }
  }
`;

const StyledDeleteContainer = styled.span`
  cursor: pointer;
`;

const StyledRedactionSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledDropdownContentContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;

  .checkbox input {
    margin: 0px 0px 0px 1px;
  }
`;

const StyledUserDemographicSelection = styled.div`
  display: flex;
  flex-direction: column;
`;
