import React, { useState } from "react";
import styled from "styled-components";

import { Tooltip } from "components/Tooltip";
import { TextType } from "ts/TextType";

type Props = {
  value: TextType | React.ReactNode;
  id?: number;
  customWidth?: string;
  renderedLines?: number;
};

export const TextTruncator = React.memo(({ id, value, customWidth, renderedLines }: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth && !renderedLines)
      setShowTooltip(true);
    if (e.currentTarget.offsetHeight < e.currentTarget.scrollHeight && renderedLines)
      setShowTooltip(true);
  };

  return (
    <StyledTextContainer
      onMouseOver={handleHover}
      onMouseOut={() => setShowTooltip(false)}
      data-tooltip-id={`${id}-tooltip`}
      customWidth={customWidth}
      renderedLines={renderedLines}
    >
      <span>{value}</span>
      {showTooltip && (
        <Tooltip tooltipId={`${id}-tooltip`} content={value as TextType} isOpen={showTooltip} />
      )}
    </StyledTextContainer>
  );
});

const StyledTextContainer = styled.div<{ renderedLines: number; customWidth: string }>`
  max-width: ${({ customWidth }) => customWidth || "100%"};
  white-space: ${({ renderedLines }) => !renderedLines && "nowrap"};
  text-overflow: ellipsis;
  overflow: hidden;
  display: ${({ renderedLines }) => renderedLines && "-webkit-box"};
  line-clamp: ${({ renderedLines }) => renderedLines};
  -webkit-line-clamp: ${({ renderedLines }) => renderedLines};
  -webkit-box-orient: vertical;
`;
