import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { AnalysisContext } from "context/AnalysisContext";

import { useResource } from "hooks/useResource";
import { saveSharing } from "services/analysis/sharing";
import {
  fetchAllUserDemographics,
  fetchDemographicHeaders,
  fetchRedactionVersions,
  fetchSharedGroupsData,
  fetchSharedUsersData,
  fetchSharingDraft,
  saveSharingDraft,
  updateSelectedUsers,
} from "store/sharing/thunks";
import {
  clearSharingState,
  initShareByDropdownOptions,
  setActiveTab,
  setIsSavingSharing,
  setShareBy,
  setStep,
  setView,
} from "store/sharing/sharingSlice";
import {
  clearUserSelectionState,
  setSelectedUsers,
  setSelectedUsersCount,
} from "store/sharing/userSelectionSlice";
import { setSelectedDemographicFilters } from "context/AnalysisContext/actions";
import {
  clearPermissionsState,
  setShowCustomConditionError,
  setShowGroupNameError,
  setShowNoSharedPageError,
  setShowRedactionCustomConditionError,
} from "store/sharing/permissionSlice";

import { getSlug } from "utils/getSlug";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { Notch } from "common-layouts/AnalysisLayout/Notch";
import { BackButton } from "components/_buttons/BackButton";
import { Select } from "components/_inputs/Select";
import { Tabs } from "components/Tabs";
import { SharingBuilder } from "pages/analysis/[id]/sharing/_layouts/SharingBuilder";
import { SelectedUserPills } from "./_layouts/SelectedUserPills";
import { SharingActionsSection } from "./_layouts/SharingActionsSection";
import { ErrorScreen } from "components/ErrorScreen";
import { LoadingDotsScreen } from "components/_screens/LoadingDotsScreen";
import { Text } from "components/Text";

import { PageErrorType } from "ts/enums/pageErrorType";
import { Color } from "ts/enums/color";
import { ShareByOption, ShareStep, SharingPageTab, SharingView } from "ts/enums/sharing";
import {
  AnalysisStatus,
  FileUploadStatus,
  RoleType,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingOverviewAccess,
  SharingSummaryAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { clearSharedWithState } from "store/sharing/sharedWithSlice";
import { setSearchTerm } from "store/search/searchbarSlice";
import { isOneCustomConditionEmpty } from "utils/sharing";

const getSharingPageTab = (slug: string) =>
  slug.includes("ShareTo") ? SharingPageTab.Edit : SharingPageTab.Show;

export const SharingPage = () => {
  const [state] = useContext(AnalysisContext);
  const sharing = useAppSelector((state) => state.sharing);
  const sharedWithState = useAppSelector((state) => state.sharedWith);
  const userSelectionState = useAppSelector((state) => state.sharingUserSelection);
  const permissionsState = useAppSelector((state) => state.permissions);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const history = useHistory();
  const location = useLocation();
  const { getResource } = useResource();

  const groupNameInputRef = useRef<HTMLInputElement>(null);
  const groupNameButtonRef = useRef<HTMLInputElement>(null);

  // Variables for the restrictions
  const isUserAnalystInHisAnalysis =
    currentUser.roleType === RoleType.Analyst && !state.analysisDetails.sharing;

  const isUserViewer = currentUser.roleType === RoleType.Viewer;

  const isUserAdmin = isAnyAdmin(currentUser.roleType);

  const analysisFailed = state.analysisDetails.status === AnalysisStatus.Failed;

  const analysisNotAnalyzed =
    state.analysisDetails.uploadStatus === FileUploadStatus.Failed ||
    state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
    state.analysisDetails.status === AnalysisStatus.InProgress;

  const baseUrl = `/analysis/${state.analysisDetails.id}`;

  const counts = {
    [ShareByOption.Users]: sharedWithState.sharedUsersApiData.totalCount,
    [ShareByOption.Groups]: sharedWithState.sharedGroupsApiData.totalCount,
  };

  const tabs = [
    {
      id: SharingPageTab.Show,
      label: (
        <>
          <Text
            resource={{
              key: `sharing.tab.${sharing.shareBy.value.toLowerCase()}SharedWith`,
            }}
          />
          <StyledShareWithCount>{counts[sharing.shareBy.value]}</StyledShareWithCount>
        </>
      ),
      href: `${baseUrl}/sharing?view=${sharing.shareBy.value}SharedWith`,
    },
    {
      id: SharingPageTab.Edit,
      label: <Text resource="sharing.tab.share" />,
      href: `${baseUrl}/sharing?view=ShareTo${sharing.shareBy.value}&step=UserSelection`,
    },
  ];

  // functions
  const handleSelectShareBy = (shareOption: ShareByOption) => {
    dispatch(setShareBy(sharing.shareByDropdownOptions.find((o) => o.value === shareOption)));

    const queryParams = new URLSearchParams(history.location.search);

    const redirectGraph = {
      [ShareByOption.Users]: {
        [SharingPageTab.Edit]: SharingView.ShareToUsers,
        [SharingPageTab.Show]: SharingView.UsersSharedWith,
      },
      [ShareByOption.Groups]: {
        [SharingPageTab.Edit]: SharingView.ShareToGroups,
        [SharingPageTab.Show]: SharingView.GroupsSharedWith,
      },
    };

    const view = redirectGraph[shareOption][sharing.activeTab];
    dispatch(setView(view));
    queryParams.set("view", view);

    // remove search query param
    queryParams.delete("search");
    dispatch(setSearchTerm(""));

    if (shareOption === ShareByOption.Groups && sharing.activeTab === SharingPageTab.Edit) {
      dispatch(fetchSharedGroupsData(state.analysisDetails.id));
    } else {
      dispatch(fetchSharedUsersData(state.analysisDetails.id));
    }

    history.push(`${baseUrl}/sharing?${queryParams.toString()}`);
  };

  const handleSave = async () => {
    //Error handling
    if (permissionsState.permissionFields.groupName.trim() === "") {
      dispatch(setShowGroupNameError(true));
      return;
    }

    let hasError;
    if (isOneCustomConditionEmpty(permissionsState.permissionFields.customDemographicConditions)) {
      dispatch(setShowCustomConditionError(true));
      hasError = true;
    }

    if (
      permissionsState.permissionFields.summaryAccess === SharingSummaryAccess.Hidden &&
      permissionsState.permissionFields.overviewAccess === SharingOverviewAccess.Hidden &&
      permissionsState.permissionFields.dashboardAccess === SharingDashboardAccess.Hidden &&
      permissionsState.permissionFields.topicExplorerAccess === SharingTopicExplorerAccess.Hidden &&
      permissionsState.permissionFields.commentExplorerAccess ===
        SharingCommentExplorerAccess.Hidden
    ) {
      dispatch(setShowNoSharedPageError(true));
      hasError = true;
    }

    if (isOneCustomConditionEmpty(permissionsState.permissionFields.redactionCustomConditions)) {
      dispatch(setShowRedactionCustomConditionError(true));
      hasError = true;
    }

    if (hasError) return;

    //Save sharing
    dispatch(setIsSavingSharing(true));
    await dispatch(saveSharingDraft(state.analysisDetails.id));
    await saveSharing(state.analysisDetails.id);

    dispatch(fetchSharedUsersData(state.analysisDetails.id));
    dispatch(fetchSharedGroupsData(state.analysisDetails.id));

    //Redirect
    const viewParam =
      sharing.view === SharingView.ShareToUsers
        ? SharingView.UsersSharedWith
        : SharingView.GroupsSharedWith;

    dispatch(setView(viewParam));

    history.push(routes.sharingPage(state.analysisDetails.id) + `?view=${viewParam}`);

    //Reset values
    resetSharingValues();
    dispatch(setIsSavingSharing(false));
    dispatch(clearPermissionsState(getResource("sharing.groupName.default")));
    dispatch(
      fetchSharingDraft({
        analysisId: state.analysisDetails.id,
        sharingMethod: getSharingMethodFromView(sharing.view),
        availableDemographicFilters: state.availableDemographicFilters,
      })
    );
  };

  const handleSwitchTab = (tabId: string) => {
    //Clear search first
    dispatch(setSearchTerm(""));

    if (tabId === SharingPageTab.Show) {
      dispatch(clearPermissionsState(getResource("sharing.groupName.default")));
    }
    if (sharing.shareBy.value === ShareByOption.Groups) {
      dispatch(
        setView(
          tabId === SharingPageTab.Show ? SharingView.GroupsSharedWith : SharingView.ShareToGroups
        )
      );
      dispatch(setShowGroupNameError(false));
      dispatch(setShowCustomConditionError(false));
      dispatch(setShowRedactionCustomConditionError(false));
    } else {
      dispatch(
        setView(
          tabId === SharingPageTab.Show ? SharingView.UsersSharedWith : SharingView.ShareToUsers
        )
      );
    }
    dispatch(setStep(ShareStep.UserSelection));
  };

  const initSharingDraft = async () => {
    await dispatch(fetchRedactionVersions(state.analysisDetails.id));
    dispatch(
      fetchSharingDraft({
        analysisId: state.analysisDetails.id,
        sharingMethod: getSharingMethodFromView(sharing.view),
        availableDemographicFilters: state.availableDemographicFilters,
      })
    );
  };

  const resetSharingValues = () => {
    dispatch(setStep(ShareStep.UserSelection));
    dispatch(setSelectedUsers([]));
    dispatch(setSelectedUsersCount(0));
    dispatch(setSelectedDemographicFilters([]));
    dispatch(clearPermissionsState(getResource("sharing.groupName.default")));
    dispatch(setShowCustomConditionError(false));
    dispatch(setShowRedactionCustomConditionError(false));
    dispatch(setShowGroupNameError(false));
  };

  //INITIAL SETUP
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const analysisId = state.analysisDetails.id;

    const initialShareByDropdownOptions = [
      { label: getResource("sharing.shareByDropdown.users"), value: ShareByOption.Users },
      { label: getResource("sharing.shareByDropdown.group"), value: ShareByOption.Groups },
    ];

    const shareBy =
      initialShareByDropdownOptions.find((o) => params.get("view")?.includes(o.value)) ||
      initialShareByDropdownOptions.find((o) => o.value === ShareByOption.Users);

    const queryParams = new URLSearchParams(window.location.search);
    const step = queryParams.get("step");
    const view = queryParams.get("view");

    //Fetch initial data
    const fileUploadFailed = state.analysisDetails.uploadStatus === FileUploadStatus.Failed;
    const isFetchingDataRestricted =
      (analysisFailed && fileUploadFailed && (isUserAdmin || isUserAnalystInHisAnalysis)) ||
      analysisNotAnalyzed ||
      (!isUserAnalystInHisAnalysis && !isAnyAdmin) ||
      isUserViewer ||
      state.loadingAnalysisDetails ||
      permissionsState.loadingSharingDraft;

    if (isFetchingDataRestricted) return;
    dispatch(fetchAllUserDemographics());
    dispatch(fetchDemographicHeaders(analysisId));

    shareBy.value === ShareByOption.Groups
      ? dispatch(fetchSharedGroupsData(state.analysisDetails.id))
      : dispatch(fetchSharedUsersData(state.analysisDetails.id));
    dispatch(updateSelectedUsers(state.analysisDetails.id));

    //Init default variables
    dispatch(setShareBy(shareBy));
    dispatch(initShareByDropdownOptions(initialShareByDropdownOptions));

    //View logic Bloc

    if (view && view !== sharing.view) {
      dispatch(setView(view as SharingView));
    } else if (!view && sharing.shareBy.value === ShareByOption.Groups) {
      queryParams.set("view", SharingView.GroupsSharedWith);
      dispatch(setView(SharingView.GroupsSharedWith));
    } else if (!view) {
      queryParams.set("view", SharingView.UsersSharedWith);
      dispatch(setView(SharingView.UsersSharedWith));
    }

    //Step logic Bloc
    if (step && step !== sharing.step) {
      dispatch(setStep(step as ShareStep));
    } else if (!step && (view === SharingView.ShareToUsers || view === SharingView.ShareToGroups)) {
      queryParams.set("step", ShareStep.UserSelection);
      dispatch(setStep(ShareStep.UserSelection));
    }

    history.push(`${baseUrl}/sharing?${queryParams.toString()}`);

    return () => {
      dispatch(clearSharedWithState());
      dispatch(clearSharingState());
      dispatch(clearPermissionsState(getResource("sharing.groupName.default")));
      dispatch(clearUserSelectionState());
    };
  }, [state.loadingAnalysisDetails, permissionsState.loadingSharingDraft]); // eslint-disable-line

  /* eslint-disable */
  useEffect(() => {
    initSharingDraft();
  }, [state.availableDemographicFilters.length]);

  useEffect(() => {
    if (!state.loadingAnalysisDetails) {
      dispatch(
        setActiveTab(
          getSharingPageTab(
            getSlug(location.search.length > 0 ? location.search : location.pathname)
          )
        )
      );
    }
  }, [location, state.loadingAnalysisDetails]); // eslint-disable-line

  // Possible restrictions on the sharing view
  if (state.loadingAnalysisDetails || state.loadingAvailableDemographicFilters) {
    return <LoadingDotsScreen />;
  }

  if (state.analysisError) {
    return <ErrorScreen errorType={state.analysisError} />;
  }

  if (analysisFailed && (isUserAdmin || isUserAnalystInHisAnalysis)) {
    return <ErrorScreen errorType={PageErrorType.AnalysisFailed} />;
  } else if (analysisNotAnalyzed) {
    return <ErrorScreen errorType={PageErrorType.AnalysisNotAnalyzed} />;
  } else if (
    ((!isUserAnalystInHisAnalysis || isUserViewer) && !isUserAdmin) ||
    state.analysisError === PageErrorType.AnalysisInsufficientPermission
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <StyledSharingPage className="fade-in">
      {/* HEADER */}
      <Notch />
      <StyledHeader>
        <BackButton
          resourceKey={"button.backToOverviewPage"}
          onClick={() => history.push(`${baseUrl}/overview`)}
        />
      </StyledHeader>
      <StyledShareByContainer>
        <span>
          <Text resource="sharing.shareByDropdown.label" />
        </span>
        <Select
          selectedOption={sharing.shareBy}
          options={sharing.shareByDropdownOptions}
          buttonStyle={{ padding: "8px 10px", width: "200px" }}
          dropdownWidth="200px"
          handleChange={handleSelectShareBy}
        />
      </StyledShareByContainer>
      <StyledTabsContainer>
        <Tabs tabs={tabs} activeTab={sharing.activeTab} onSelectTab={handleSwitchTab} />
      </StyledTabsContainer>

      {/* CONTENT */}
      <SharingBuilder
        groupNameInputRef={groupNameInputRef}
        groupNameButtonRef={groupNameButtonRef}
      />
      {sharing.activeTab === SharingPageTab.Edit &&
        userSelectionState.selectedUsers.length > 0 &&
        sharing.step === ShareStep.UserSelection && (
          <div style={{ marginTop: "16px" }}>
            <SelectedUserPills />
          </div>
        )}

      {/* BOTTOM CTAs */}
      {[SharingView.ShareToGroups, SharingView.ShareToUsers].includes(sharing.view) ? (
        <SharingActionsSection
          onSave={handleSave}
          groupNameInputRef={groupNameInputRef}
          groupNameButtonRef={groupNameButtonRef}
        />
      ) : (
        <StyledFullHeightUtilDiv />
      )}
    </StyledSharingPage>
  );
};

const StyledHeader = styled.div`
  padding: 30px 0px 16px 0px;
`;

const StyledTabsContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledFullHeightUtilDiv = styled.div`
  height: 100%;
`;

const StyledShareByContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;

  span {
    color: ${Color.gray50};
    font-size: 15px;
    font-weight: bold;
  }
`;

const StyledShareWithCount = styled.span`
  font-size: 14px;
  color: ${Color.gray50};
  font-weight: normal;
  background-color: ${Color.neutral10};
  padding: 3px 10px;
  margin-left: 6px;
  border-radius: 5px;
`;

const StyledSharingPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
