import { LanguageCode } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Feature } from "ts/enums/feature";

type SettingsState = {
  features: { [key in Feature]: boolean };
  uiLanguages: LanguageCode[];
};

const initialState: SettingsState = {
  features: {
    redaction: false,
    preferredLanguage: false,
    multilingualAnalysis: false,
    precision: false,
    llm: false,
    multiLingualUi: false,
  },
  uiLanguages: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setRedactionActive: (state, action: PayloadAction<boolean>) => {
      state.features.redaction = action.payload;
    },
    setLLMActive: (state, action: PayloadAction<boolean>) => {
      state.features.llm = action.payload;
    },
    setMultiLingualUi: (state, action: PayloadAction<boolean>) => {
      state.features.multiLingualUi = action.payload;
    },
    setUiLanguages: (state, action: PayloadAction<LanguageCode[]>) => {
      state.uiLanguages = action.payload;
    },
  },
});

export const { setRedactionActive, setLLMActive, setMultiLingualUi, setUiLanguages } =
  settingsSlice.actions;

export default settingsSlice.reducer;
