import React, { ReactNode } from "react";

import { Color } from "ts/enums/color";
import { Pill, PillSize } from "./Pill";
import { PillType } from "ts/enums/pillType";

type Props = {
  children: ReactNode;
  pillType: PillType;
  isDisabled?: boolean;
  customStyles?: Record<string, any>;
  showPill?: boolean;
  handleDelete?: () => void;
  handleClick?: () => void;
};

export const ToggeablePill = ({
  pillType,
  isDisabled,
  children,
  customStyles,
  showPill,
  handleDelete,
  handleClick,
}: Props) => {
  const pillStyles = {
    [PillType.SelectAll]: {
      background: Color.blue50,
      color: Color.white,
      iconColor: Color.white,
    },
    [PillType.SingleSelect]: {
      background: Color.white,
      color: Color.blue50,
      iconColor: Color.blue50,
    },
  };

  return (
    <Pill
      round
      applyGridMargin
      backgroundColor={isDisabled ? Color.neutral10 : pillStyles[pillType].background}
      border={`1px solid ${isDisabled ? Color.gray20 : Color.blue50}`}
      size={PillSize.xl}
      style={{
        color: isDisabled ? Color.gray20 : pillStyles[pillType].color,
        display: showPill && "none",
        ...customStyles,
      }}
      iconColor={pillStyles[pillType].iconColor}
      onClick={handleClick}
      onDeletePill={handleDelete}
      disabled={isDisabled}
    >
      {children}
    </Pill>
  );
};
