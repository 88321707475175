import {
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingOverviewAccess,
  SharingSummaryAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { routes } from "routes";
import { getAnalysisDetails } from "services/analysis";
import { PreviewUser } from "ts/analysis";

export const getDefaultAnalysisPage = async (
  analysisId: number,
  llmActive: boolean,
  queryParams?: string,
  previewUser?: PreviewUser
): Promise<string> => {
  const { data } = !previewUser && (await getAnalysisDetails(analysisId));

  const sharingPermissions = {
    summaryAccess: data?.sharingProperties?.summaryAccess || previewUser?.summaryAccess,
    overviewAccess: data?.sharingProperties?.overviewAccess || previewUser?.overviewAccess,
    dashboardAccess: data?.sharingProperties?.dashboardAccess || previewUser?.dashboardAccess,
    topicExplorerAccess:
      data?.sharingProperties?.topicExplorerAccess || previewUser?.topicExplorerAccess,
    commentExplorerAccess:
      data?.sharingProperties?.commentExplorerAccess || previewUser?.commentExplorerAccess,
  };

  if (!data?.sharingProperties && !previewUser)
    return llmActive
      ? routes.summaryPage(analysisId, queryParams)
      : routes.overviewPage(analysisId, queryParams);

  const accessChecks = [
    {
      key: "summaryAccess",
      type: SharingSummaryAccess.Shared,
      route: routes.summaryPage(analysisId, queryParams),
    },
    {
      key: "overviewAccess",
      type: SharingOverviewAccess.Shared,
      route: routes.overviewPage(analysisId, queryParams),
    },
    {
      key: "dashboardAccess",
      type: SharingDashboardAccess.Shared,
      route: routes.dashboardPage(analysisId, queryParams),
    },
    {
      key: "topicExplorerAccess",
      type: SharingTopicExplorerAccess.Shared,
      route: routes.topicsPage(analysisId, queryParams),
    },
    {
      key: "commentExplorerAccess",
      type: SharingCommentExplorerAccess.Shared,
      route: routes.commentsPage(analysisId, queryParams),
    },
  ];

  for (const { key, type, route } of accessChecks) {
    if (sharingPermissions[key] === type) {
      return route;
    }
  }

  return null;
};
