import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import CreatableSelect from "react-select/creatable";

import { getCategoryStyles, getTagStyles } from "./helpers";
import { useResource } from "hooks/useResource";
import { useAppDispatch, useAppSelector } from "store";
import {
  CreatableSelectOption,
  setTags,
  setSelectedCategory,
  setNote,
  setShowCharacterCount,
} from "store/pin/pinSlice";

import { Button } from "components/_buttons/Button";
import { Popup } from "components/Popup";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { PopupPosition } from "ts/enums/popupPosition";
import { Color } from "ts/enums/color";
import { fetchCategories, fetchTags } from "store/pin/thunk";
import { createPinForComments, unpinComment } from "services/comments";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { NOTE_CHAR_LIMIT_PIN } from "assets/constants/noteCharacterLimit";
import { useQueryParams } from "hooks/useQueryParams";
import { ErrorType } from "@explorance/mly-types";

type Props = {
  anchorEl: HTMLElement;
  bulkCommentIds?: number[];
  setIsPopupOpen: (boolean) => void;
  refetchComments: () => void;
};

const TAG_INPUT_MAX_LENGTH = 100;

export const PinPopup = ({ anchorEl, setIsPopupOpen, bulkCommentIds, refetchComments }: Props) => {
  const state = useAppSelector((state) => state.commentPin);
  const dispatch = useAppDispatch();
  const { sharingPreview } = useQueryParams();

  const [isCategoryInputActive, setIsCategoryInputActive] = useState<boolean>(false);

  const commentIds = bulkCommentIds ? bulkCommentIds : [state.comment.id];

  const { getResource } = useResource();

  const pinCommentHandler = async () => {
    const pinArgs = {
      ids: commentIds,
      category: state.selectedCategory.value,
      ...(state.note.trim().length > 0 && { note: state.note.trim() }),
      ...(state.tags.length > 0 && { tags: state.tags.map((tag) => tag.value) }),
    };

    try {
      await createPinForComments(pinArgs);
      dispatch(showToastSuccess("toast.pinSuccessful"));
      refetchComments();
    } catch (error: any) {
      if (error.response.data.details[0].message === ErrorType.BulkPinLimitExceeded) {
        dispatch(showToastError("toast.bulkBookmarkError.limitReached"));
      } else {
        dispatch(showToastError("toast.defaultError"));
      }
    } finally {
      setIsPopupOpen(false);
    }
  };

  const unpinHandler = async () => {
    try {
      await unpinComment(commentIds);
      dispatch(showToastSuccess("toast.unpinSuccessful"));
    } catch {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      refetchComments();
      setIsPopupOpen(false);
    }
  };

  const handleBlur = () => {
    if (state.note.length > 0) return;
    dispatch(setShowCharacterCount(false));
  };

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchTags());
  }, []); // eslint-disable-line

  return (
    <Popup
      isOpen
      anchorEl={anchorEl}
      placement={bulkCommentIds ? PopupPosition.bottomStart : PopupPosition.bottomEnd}
      popupStyles={{ width: 460, maxHeight: 540, padding: 10 }}
      fixedPopup
    >
      <StyledContentContainer>
        <StyledCommentContainer>
          <StyledHeader removeMargin={!!bulkCommentIds}>
            {bulkCommentIds ? (
              <Text
                resource={{
                  key:
                    bulkCommentIds.length > 1
                      ? "comments.pinComment.popup.bulk.title"
                      : "comments.pinComment.popup.bulk.title.singular",
                  args: [`${bulkCommentIds.length}`],
                }}
              />
            ) : (
              <Text resource="comments.pinComment.popup.header" />
            )}
          </StyledHeader>
          <StyledComment>{!bulkCommentIds && state.comment.value}</StyledComment>
        </StyledCommentContainer>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.category" />
          </StyledLabel>
          <CreatableSelect
            options={state.categories.map((cat) => ({ label: cat.name, value: cat.name }))}
            styles={getCategoryStyles(isCategoryInputActive)}
            value={state.selectedCategory}
            onChange={(newValue) =>
              dispatch(setSelectedCategory(newValue as CreatableSelectOption))
            }
            placeholder={getResource("comments.pinComment.popup.placeholder")}
            onFocus={() => setIsCategoryInputActive(true)}
            onBlur={() => setTimeout(() => setIsCategoryInputActive(false), 150)}
            blurInputOnSelect
            isSearchable
          />
        </div>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.tags" />
          </StyledLabel>
          <CreatableSelect
            options={state.tagOptions.map((tag) => ({ label: tag.name, value: tag.name }))}
            styles={getTagStyles()}
            components={{
              DropdownIndicator: null,
            }}
            value={state.tags}
            onChange={(newValue) => dispatch(setTags(newValue as CreatableSelectOption[]))}
            onInputChange={(inputValue) =>
              inputValue.length <= TAG_INPUT_MAX_LENGTH
                ? inputValue
                : inputValue.slice(0, TAG_INPUT_MAX_LENGTH)
            }
            placeholder={getResource("comments.pinComment.popup.input.placeholder")}
            menuPlacement="top"
            blurInputOnSelect
            isSearchable
            isMulti
          />
        </div>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.notes" />
          </StyledLabel>
          <StyledInputContainer showCharacterCount={state.showCharacterCount}>
            <StyledInput
              maxLength={NOTE_CHAR_LIMIT_PIN}
              placeholder={getResource("comments.pinComment.popup.notes.placeholder")}
              onChange={(e) => dispatch(setNote(e.target.value))}
              value={state.note}
              height={100}
              onBlur={handleBlur}
              onFocus={() => dispatch(setShowCharacterCount(true))}
              showCharacterCount={state.showCharacterCount}
            />
            {state.showCharacterCount && (
              <StyledCharacterCount>
                <span>{state.note.length}</span>
                <Text
                  resource={{
                    key: "input.validation.characterCount",
                    args: [`${NOTE_CHAR_LIMIT_PIN}`],
                  }}
                />
              </StyledCharacterCount>
            )}
          </StyledInputContainer>
        </div>
        <StyledActionSection>
          {!bulkCommentIds && (
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.sm}
              onClick={unpinHandler}
              disabled={state.comment?.pin.category === "" || !!sharingPreview}
            >
              <Text resource="comments.pinComment.popup.button.unpinComment" />
            </Button>
          )}
          <div>
            <Button
              variant={ButtonVariant.light}
              size={ButtonSize.ml}
              onClick={() => setIsPopupOpen(false)}
            >
              <Text resource="button.cancel" />
            </Button>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.ml}
              disabled={!state.selectedCategory || isCategoryInputActive || !!sharingPreview}
              onClick={pinCommentHandler}
            >
              <Text resource="comments.pinComment.popup.button.pinComment" />
            </Button>
          </div>
        </StyledActionSection>
      </StyledContentContainer>
    </Popup>
  );
};

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHeader = styled.div<{ removeMargin: boolean }>`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: ${({ removeMargin }) => !removeMargin && "5px"};
`;

const StyledCommentContainer = styled.div`
  padding: 12px;
  background-color: ${Color.neutral10};
`;

const StyledComment = styled.div`
  color: ${Color.gray50};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StyledLabel = styled.div`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 18px 0;

  div {
    display: flex;
    gap: 12px;
    margin-left: auto;
  }
`;

const StyledInputContainer = styled.div<{ showCharacterCount: boolean }>`
  width: 100%;
  border: 1px solid ${Color.sky50};
  ${({ showCharacterCount }) =>
    showCharacterCount &&
    css`
      padding: 8px 10px;
    `};
  :focus-within {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledInput = styled.textarea<{ height: number; showCharacterCount: boolean }>`
  width: 100%;
  height: ${({ height }) => height}px;
  border-radius: 2px;
  color: ${Color.gray50};
  font-size: 15px;
  resize: none;
  border: none;
  cursor: auto;

  ${({ showCharacterCount }) =>
    !showCharacterCount &&
    css`
      overflow: hidden;
      padding: 8px 10px;
    `};

  ::placeholder {
    color: ${Color.gray20};
  }

  :focus {
    outline: none;
  }
`;

const StyledCharacterCount = styled.div`
  color: ${Color.gray40};
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;
