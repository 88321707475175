import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  clearState,
  setBlockRouteChange,
  setHasErrors,
  setIsValidToSave,
  setRedactionSetId,
} from "store/redaction/redactionBuilderSlice";
import { editRedactionSet, fetchRedactionSet } from "store/redaction/thunks";
import { RedactionBuilder } from "common-layouts/RedactionBuilder";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";
import { ErrorScreen } from "components/ErrorScreen";
import { PageErrorType } from "ts/enums/pageErrorType";
import { RoleType } from "@explorance/mly-types";

export const EditRedactionPage = () => {
  const state = useAppSelector((state) => state.redactionBuilder);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const redactionId = useParams<{ redactionId: string }>().redactionId;

  useEffect(() => {
    if (redactionId) {
      dispatch(setRedactionSetId(Number(redactionId)));
      dispatch(fetchRedactionSet());
    }
    return () => {
      dispatch(clearState());
    };
  }, []); //eslint-disable-line

  const handleUpdateRedaction = async (next) => {
    if (state.isLoading) return;
    if (state.inputtedRedactionList.length === 0) {
      dispatch(setHasErrors(true));
      dispatch(setIsValidToSave(false));
      return;
    }
    await dispatch(editRedactionSet(Number(redactionId)));
    await dispatch(fetchRedactionSet());
    dispatch(setBlockRouteChange(false));
    next();
  };

  if (!IsFeatureActive(Feature.Redaction)) {
    return <ErrorScreen errorType={PageErrorType.RedactionFeatureNotActivated} />;
  } else if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return <RedactionBuilder handleSave={handleUpdateRedaction} />;
};
