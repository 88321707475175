import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useAppDispatch, useAppSelector } from "store";
import { fetchLicensePeriods, fetchLicensePeriodStatistics } from "store/licenseManagement/thunks";
import {
  setPeriodDropdownOptions,
  setSelectedPeriod,
} from "store/licenseManagement/quotaMenuSlice";

import { StyledSectionHeader, SectionTitle } from "pages/licensing/_layouts/SectionTitle";
import { BlockRow } from "pages/licensing/_layouts/blocks/BlockRow";
import { QuotaMenu } from "pages/licensing/QuotaMenu";
import { RowLabel } from "pages/licensing/_layouts/RowLabel";
import { Pill } from "components/_pills/Pill";
import { StackedBarChart } from "components/StackedBarChart";
import { Skeleton } from "components/Skeleton";
import { Select } from "components/_inputs/Select";
import { EmptyState } from "components/EmptyState";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { RoleType } from "@explorance/mly-types";
import { ButtonVariant } from "ts/enums/button";
import { EmptyStateType } from "ts/enums/emptyStateType";

type PercentageColors = {
  pillColor: Color;
  barColor: Color;
};

export const CommentsAnalyzed = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.quotaMenu);
  const dispatch = useAppDispatch();

  const { getResource } = useResource();

  const selectedLicensingPeriod = state.licensingPeriods.filter(
    (lp) => lp.id === parseInt(state.selectedPeriod?.value)
  )[0];

  const hasValidLicensingPeriod = state.licensingPeriods.length > 0;

  // functions
  const getPercentageColor = (percentage: number): PercentageColors => {
    if (percentage > 50 && percentage < 80) {
      return { pillColor: Color.orange20, barColor: Color.orange50 };
    } else if (percentage > 80) {
      return { pillColor: Color.red10, barColor: Color.red50 };
    }
    return { pillColor: Color.green30, barColor: Color.green50 };
  };

  const percentageColors: PercentageColors = getPercentageColor(state.percentageUsed);

  useEffect(() => {
    const undefinedOption = {
      label: getResource("license.dropdown.label.undefined"),
      value: undefined,
    };
    dispatch(setSelectedPeriod(undefinedOption));
    dispatch(setPeriodDropdownOptions([undefinedOption]));
    dispatch(fetchLicensePeriods());
  }, []); // eslint-disable-line

  // Partial render
  const getCommentsAnalyzedBar = (): ReactNode => {
    if (state.isLoading) {
      return (
        <StyledSkeletonContainer className="pulse">
          <Skeleton
            width={1140}
            height={12}
            backgroundColor={Color.blue20}
            responsive={true}
            customStyles={{ position: "absolute" }}
          />
        </StyledSkeletonContainer>
      );
    }

    const chartPieces = [
      { portion: state.percentageUsed, color: percentageColors.barColor },
      { portion: 100 - state.percentageUsed, color: Color.blue20 },
    ];
    return <StackedBarChart chartPieces={chartPieces} />;
  };

  return (
    <>
      <StyledSectionHeader>
        <StyledHeaderContentContainer>
          <SectionTitle
            titleKey="licensing.section.title.commentsAnalyzed"
            isLoading={state.isLoading}
          />

          {!state.isLoading && (
            <>
              {hasValidLicensingPeriod && (
                <Pill
                  backgroundColor={percentageColors.pillColor}
                  color={percentageColors.barColor}
                  style={{ fontWeight: "bold" }}
                >
                  <Text
                    resource={{
                      key: "licensing.commentsAnalyzed.title",
                      args: [`${state.percentageUsed}`],
                    }}
                  />
                </Pill>
              )}
              <Select
                selectedOption={state.selectedPeriod}
                options={state.periodDropdownOptions}
                handleChange={
                  hasValidLicensingPeriod
                    ? (periodId) => dispatch(fetchLicensePeriodStatistics(parseInt(periodId)))
                    : undefined
                }
                dropdownWidth="100%"
                buttonVariant={ButtonVariant.light}
                buttonStyle={{
                  width: "100%",
                  fontSize: "15px",
                  padding: "12px 20px 12px 10px",
                  gap: "4px",
                }}
              />
            </>
          )}
        </StyledHeaderContentContainer>
        {(currentUser?.roleType === RoleType.SuperAdmin ||
          currentUser?.roleType === RoleType.Admin) &&
          !state.isLoading && <QuotaMenu periodId={parseInt(state.selectedPeriod?.value)} />}
      </StyledSectionHeader>

      <StyledContentContainer validLicensePeriod={hasValidLicensingPeriod}>
        {state.isLoading ? (
          <>
            <StyledHeader className="pulse">
              <Skeleton width={150} height={18} backgroundColor={Color.neutral10} />
              <Skeleton width={150} height={18} backgroundColor={Color.neutral10} />
            </StyledHeader>
          </>
        ) : !hasValidLicensingPeriod ? (
          <EmptyState type={EmptyStateType.noLicensingPeriod} />
        ) : (
          <StyledHeader>
            <b>
              <Text resource="licensing.commentsAnalyzed.title" />
            </b>
            <b>
              <Text
                resource={{
                  key: "licensing.commentsAnalyzed.totalCountLabel",
                  args: [
                    `${new Intl.NumberFormat().format(state.totalCommentsAnalyzed)}`,
                    `${new Intl.NumberFormat().format(selectedLicensingPeriod?.commentLimit)}`,
                  ],
                }}
              />
            </b>
          </StyledHeader>
        )}
        <div>{getCommentsAnalyzedBar()}</div>
        {!state.isLoading &&
          state.modelStatistics.map((ms, index) => (
            <BlockRow
              key={index}
              rowLabel={<RowLabel name={ms.name} catType={ms.modelFamily} />}
              countLabel="licensing.commentsAnalyzed.countLabel"
              count={ms.analyzedCount}
            />
          ))}
      </StyledContentContainer>
    </>
  );
};

const StyledHeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 37px;
`;

const StyledSkeletonContainer = styled.div`
  height: 12px;
  position: relative;
  width: 100%;

  @media (min-width: 1920px) {
    width: 1140px;
    overflow: hidden;
  }
`;

const StyledContentContainer = styled.div<{ validLicensePeriod: boolean }>`
  padding: 16px;
  border-radius: 2px;
  background-color: ${Color.white};
  border: ${({ validLicensePeriod }) => validLicensePeriod && "1px solid ${Color.sky50}"};
  box-shadow: ${({ validLicensePeriod }) => validLicensePeriod && "0px 3px 10px 0px #35384d33"};
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
