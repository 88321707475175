import React from "react";
import { useAppSelector } from "store";
import styled, { css } from "styled-components";

import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { SharingView } from "ts/enums/sharing";

const editButtonResourceKeys = {
  [SharingView.GroupsSharedWith]: "button.editGroup",
  [SharingView.UsersSharedWith]: "button.edit",
};

type Props = {
  editButtonStyle?: Record<string, string>;
  disabled?: boolean;
  handleClickEdit?: () => void;
  handleClickDelete?: () => void;
};

export const ActionsCell = ({
  editButtonStyle,
  disabled = false,
  handleClickEdit,
  handleClickDelete,
}: Props) => {
  const { view } = useAppSelector((state) => state.sharing);

  return (
    <StyledActionsCell isButtonDisabled={disabled}>
      <div>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.sm}
          style={{ color: Color.red50, marginRight: 5 }}
          onClick={disabled ? undefined : handleClickDelete}
        >
          <Icon type={IconType.xCircle} size={14} style={{ marginRight: 5 }} />
          <Text resource="button.unshare" />
        </Button>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.sm}
          style={editButtonStyle}
          onClick={disabled ? undefined : handleClickEdit}
        >
          <Icon type={IconType.gear} size={14} style={{ marginRight: 5 }} color={Color.gray30} />
          <Text resource={editButtonResourceKeys[view]} />
        </Button>
      </div>
    </StyledActionsCell>
  );
};

const StyledActionsCell = styled.div<{ isButtonDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    ${({ isButtonDisabled }) =>
      isButtonDisabled &&
      css`
        &:hover {
          background-color: ${Color.sky20};
          cursor: default;
        }
      `}
  }
`;
