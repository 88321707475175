import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "store";

import {
  setRedactionRuleMethod,
  setRedactionRuleSet,
  setRedactionRuleReplaceWithValue,
  removeRedactionRule,
  setRedactionRuleAlertRange,
} from "store/redaction/redactionModalSlice";
import { useResource } from "hooks/useResource";

import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";
import { Icon, IconType } from "components/_icons/Icon";
import { Select } from "components/_inputs/Select";
import { TextInput } from "components/_inputs/TextInput";
import { RangeSlider } from "components/RangeSlider";

import { Color } from "ts/enums/color";
import { SelectOption } from "ts/select";
import { RedactionRule, RedactionSetItem } from "ts/redaction";
import {
  RedactionDisplayMethod,
  RedactionListType,
  RedactionRuleType,
} from "@explorance/mly-types";

type Props = {
  rule: RedactionRule;
  index: number;
  isSystemType?: boolean;
};

export const RedactionRuleCard = ({ rule, index, isSystemType }: Props) => {
  const redactionModalState = useAppSelector((state) => state.redactionModal);
  const { getResource } = useResource();
  const dispatch = useAppDispatch();

  const handleSelectSet = (name: string) => {
    const setId = redactionModalState.redactionSets.find((set) => set.name === name)?.id;
    dispatch(
      setRedactionRuleSet({
        index,
        redactionSetId: setId || null,
        ...(!setId && { isAlertType: true }),
        defaultReplacementValue: !setId
          ? getResource("comments.redactModal.redactedComment.redacted")
          : getResource("redaction.version.defaultReplacementValue"),
      })
    );
  };

  const getDisplayMethodLabel = (displayMethod: RedactionDisplayMethod) => {
    return (
      <StyledDisplayMethodCard>
        <StyledCardTitle>
          <Text resource={`modal.redaction.assign.rules.displayMethod.[${displayMethod}]`} />
        </StyledCardTitle>
        <StyledCardDescription>
          <Text
            resource={`modal.redaction.assign.rules.displayMethod.[${displayMethod}].description`}
          />
        </StyledCardDescription>
      </StyledDisplayMethodCard>
    );
  };

  const getDropdownOptions = (): SelectOption[] => {
    switch (rule.ruleType) {
      case RedactionRuleType.Term: {
        return Object.values(RedactionDisplayMethod)
          .filter((method) => method !== RedactionDisplayMethod.Remove)
          .map((displayMethod) => ({
            label: getDisplayMethodLabel(displayMethod),
            value: displayMethod,
          }));
      }
      case RedactionRuleType.Alert: {
        return Object.values(RedactionDisplayMethod)
          .filter((method) => method !== RedactionDisplayMethod.Partial)
          .map((displayMethod) => ({
            label: getDisplayMethodLabel(displayMethod),
            value: displayMethod,
          }));
      }
    }
  };

  const getRedactionListLabel = (redaction: RedactionSetItem) => {
    return (
      <StyledRedactionLabel>
        <TextTruncator id={redaction.id} value={redaction.name} />
        {redaction.type === RedactionListType.System && (
          <StyledCreatedByAdminLabel>
            <Text resource="redaction.label.createdByAdmin" />
          </StyledCreatedByAdminLabel>
        )}
      </StyledRedactionLabel>
    );
  };

  const getAlertTypeLabel = () => {
    return {
      label: (
        <StyledRedactionLabel>
          <Icon type={IconType.mostAlerts} size={16} />
          <Text resource="modal.redaction.assign.rules.selectItem.alert" />
        </StyledRedactionLabel>
      ),
      value: getResource("modal.redaction.assign.rules.selectItem.alert"),
    };
  };

  const getRedactionSelectOptions = () => {
    const redactionSets = isSystemType
      ? redactionModalState.redactionSets.filter((set) => set.type === RedactionListType.System)
      : redactionModalState.redactionSets;

    return redactionSets
      .filter((set) => !set.isSelected)
      .map((redaction) => ({
        label: getRedactionListLabel(redaction),
        value: redaction.name,
      }));
  };

  const getSelectedSetLabel = () => {
    return rule.ruleType === RedactionRuleType.Alert
      ? getResource("modal.redaction.assign.rules.selectItem.alert")
      : redactionModalState.redactionSets.find((set) => set?.id === rule?.redactionSetId)?.name ||
          null;
  };

  return (
    <StyledRedactionRule>
      <StyledParamsContainer>
        <StyledMainParams>
          <StyledLabeledInput>
            <StyledLabelTitle>
              <Text resource="modal.redaction.assign.rules.selectItem" />
            </StyledLabelTitle>
            <Select
              selectedOption={{
                label: getSelectedSetLabel(),
                value: `${rule.redactionSetId}` || null,
              }}
              options={[getAlertTypeLabel()].concat(...getRedactionSelectOptions())}
              handleChange={(name) => handleSelectSet(name)}
              buttonStyle={{ width: "350px" }}
              dropdownPosition={{ type: "absolute" }}
              dropdownMaxHeight="180px"
              placeholderKey="modal.redaction.assign.rules.selectItem.placeholder"
            />
          </StyledLabeledInput>
          <StyledLabeledInput>
            <StyledLabelTitle>
              <Text resource="modal.redaction.assign.rules.displayMethod.title" />
            </StyledLabelTitle>
            <Select
              selectedOption={{
                label:
                  (rule.displayMethod &&
                    getResource(
                      `modal.redaction.assign.rules.displayMethod.[${rule.displayMethod}]`
                    )) ||
                  null,
                value: rule.displayMethod || null,
              }}
              options={getDropdownOptions()}
              handleChange={(displayMethod) =>
                dispatch(
                  setRedactionRuleMethod({
                    index,
                    displayMethod,
                    defaultReplacementValue: getResource(
                      displayMethod === RedactionDisplayMethod.Total
                        ? "comments.redactModal.redactedComment.redacted"
                        : "redaction.version.defaultReplacementValue"
                    ),
                  })
                )
              }
              buttonStyle={{ width: "100%" }}
              dropdownPosition={{ type: "absolute" }}
              placeholderKey="modal.redaction.assign.rules.displayMethod.placeholder"
            />
          </StyledLabeledInput>
        </StyledMainParams>

        {rule.displayMethod !== RedactionDisplayMethod.Remove && (
          <StyledSecondaryParams>
            <StyledLabeledInput>
              <Text resource="modal.redaction.assign.rules.replaceWith" />
              <TextInput
                value={rule.replacementValue}
                onChange={(replaceWith) =>
                  dispatch(
                    setRedactionRuleReplaceWithValue({
                      index,
                      replaceWith: replaceWith.target.value,
                    })
                  )
                }
                customStyles={{ width: "100%" }}
              />
            </StyledLabeledInput>
          </StyledSecondaryParams>
        )}
        {rule.alertRange && (
          <StyledRangeSliderContainer>
            <RangeSlider
              currentMin={rule.alertRange.min}
              currentMax={rule.alertRange.max}
              width={240}
              onChange={(alertRange) => dispatch(setRedactionRuleAlertRange({ index, alertRange }))}
            />
          </StyledRangeSliderContainer>
        )}
      </StyledParamsContainer>
      <StyledRemoveButton onClick={() => dispatch(removeRedactionRule(index))}>
        <Icon type={IconType.xCircle} size={14} />
      </StyledRemoveButton>
    </StyledRedactionRule>
  );
};

const StyledRedactionRule = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${Color.blue20};
  border-radius: 5px;
`;

const StyledParamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  gap: 10px;
`;

const StyledRemoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.neutral10};
  cursor: pointer;
  width: 25px;
  padding: 6px;
`;

const StyledMainParams = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledLabeledInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const StyledLabelTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const StyledSecondaryParams = styled.div`
  display: flex;
  width: 100%;
`;

const StyledDisplayMethodCard = styled.div`
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  gap: 3px;
`;
const StyledCardTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const StyledCardDescription = styled.div`
  font-size: 12px;
`;

const StyledRedactionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledCreatedByAdminLabel = styled.div`
  padding: 5px 8px;
  font-size: 12px;
  min-width: fit-content;
  background-color: ${Color.indigo10};
  color: ${Color.indigo50};
`;

const StyledRangeSliderContainer = styled.div`
  margin: 10px 0px 16px 0px;
`;
