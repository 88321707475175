import {
  ManualRedactionPermission,
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingOverviewAccess,
  SharingSummaryAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EditGroupModalView } from "ts/enums/editGroupModalView";
import { DemographicFilterHeader, DemographicFilter } from "ts/filters/demographicFilter";
import { RedactionVersion } from "ts/redaction";
import { DemographicCustomCondition, RedactionCustomCondition } from "ts/sharing";
import { fetchSharingDraftBySharingId } from "./thunks";
import { getVisibleCommentDemsFromData, getVisibleDemFiltersFromData } from "utils/sharing";

type editSharingBlockState = {
  selectedUsersCount: number;
  editGroupModalView: EditGroupModalView;
  selectedPermissionLevel: PermissionLevel;
  selectedDemographics: DemographicFilter[];
  selectedRedactionVersion: RedactionVersion;
  selectedCustomConditions: DemographicCustomCondition[];
  selectedRedactionCustomConditions: RedactionCustomCondition[];
  manualRedactionPermission: ManualRedactionPermission;
  allowPin: boolean;
  showCustomConditionError: boolean;
  showRedactionCustomConditionError: boolean;
  visibleDemographicsFilters: DemographicFilterHeader[];
  summaryAccess: SharingSummaryAccess;
  overviewAccess: SharingOverviewAccess;
  dashboardAccess: SharingDashboardAccess;
  topicExplorerAccess: SharingTopicExplorerAccess;
  commentExplorerAccess: SharingCommentExplorerAccess;
  showNoSharedPageError: boolean;
  allowExportData: boolean;
  allDataLoaded: boolean;
  isLoading: boolean;
  isModalOpen: boolean;
  editedGroupName: string;
};

const initialState: editSharingBlockState = {
  selectedUsersCount: 0,
  editGroupModalView: EditGroupModalView.edit,
  selectedPermissionLevel: PermissionLevel.View,
  selectedDemographics: [],
  selectedRedactionVersion: null,
  selectedCustomConditions: [],
  selectedRedactionCustomConditions: [],
  allowPin: false,
  showCustomConditionError: false,
  showRedactionCustomConditionError: false,
  manualRedactionPermission: null,
  visibleDemographicsFilters: [],
  summaryAccess: SharingSummaryAccess.Hidden,
  overviewAccess: SharingOverviewAccess.Hidden,
  dashboardAccess: SharingDashboardAccess.Hidden,
  topicExplorerAccess: SharingTopicExplorerAccess.Hidden,
  commentExplorerAccess: SharingCommentExplorerAccess.Hidden,
  allowExportData: false,
  showNoSharedPageError: false,
  allDataLoaded: false,
  isLoading: false,
  isModalOpen: false,
  editedGroupName: "",
};

const editSharingBlockSlice = createSlice({
  name: "editSharingBlockSlice",
  initialState,
  reducers: {
    setSelectedUsersCount: (state, action: PayloadAction<number>) => {
      state.selectedUsersCount = action.payload;
    },
    setEditGroupModalView: (state, action: PayloadAction<EditGroupModalView>) => {
      state.editGroupModalView = action.payload;
    },
    setSelectedPermissionLevel: (state, action: PayloadAction<PermissionLevel>) => {
      state.selectedPermissionLevel = action.payload;
    },
    setSelectedDemographics: (state, action: PayloadAction<DemographicFilter[]>) => {
      state.selectedDemographics = action.payload;
    },
    setShowNoSharedPageError: (state, action: PayloadAction<boolean>) => {
      state.showNoSharedPageError = action.payload;
    },
    setManualRedactionPermission: (state, action: PayloadAction<ManualRedactionPermission>) => {
      state.manualRedactionPermission = action.payload;
    },
    setSelectedRedactionVersion: (state, action: PayloadAction<RedactionVersion>) => {
      state.selectedRedactionVersion = action.payload;
    },
    setSelectedCustomConditions: (state, action: PayloadAction<DemographicCustomCondition[]>) => {
      state.selectedCustomConditions = action.payload;
    },
    setSelectedRedactionCustomConditions: (
      state,
      action: PayloadAction<RedactionCustomCondition[]>
    ) => {
      state.selectedRedactionCustomConditions = action.payload;
    },
    setShowCustomConditionError: (state, action: PayloadAction<boolean>) => {
      state.showCustomConditionError = action.payload;
    },
    setShowRedactionCustomConditionError: (state, action: PayloadAction<boolean>) => {
      state.showRedactionCustomConditionError = action.payload;
    },
    setAllowPin: (state, action: PayloadAction<boolean>) => {
      state.allowPin = action.payload;
    },
    setVisibleDemographicsFilters: (state, action: PayloadAction<DemographicFilterHeader[]>) => {
      state.visibleDemographicsFilters = action.payload;
    },
    setSummaryAccess: (state, action: PayloadAction<SharingSummaryAccess>) => {
      state.summaryAccess = action.payload;
      if (action.payload) {
        state.showNoSharedPageError = false;
      }
    },
    setOverviewAccess: (state, action: PayloadAction<SharingOverviewAccess>) => {
      state.overviewAccess = action.payload;
      if (action.payload) {
        state.showNoSharedPageError = false;
      }
    },
    setDashboardAccess: (state, action: PayloadAction<SharingDashboardAccess>) => {
      state.dashboardAccess = action.payload;
      if (action.payload) {
        state.showNoSharedPageError = false;
      }
    },
    setTopicExplorerAccess: (state, action: PayloadAction<SharingTopicExplorerAccess>) => {
      state.topicExplorerAccess = action.payload;
      if (action.payload) {
        state.showNoSharedPageError = false;
      }
    },
    setCommentExplorerAccess: (state, action: PayloadAction<SharingCommentExplorerAccess>) => {
      state.commentExplorerAccess = action.payload;
      if (action.payload) {
        state.showNoSharedPageError = false;
      }
    },
    setAllowExportData: (state, action: PayloadAction<boolean>) => {
      state.allowExportData = action.payload;
    },
    setAllDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.allDataLoaded = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setEditedGroupName: (state, action: PayloadAction<string>) => {
      state.editedGroupName = action.payload;
    },
    resetSharingErrors: (state) => {
      state.showCustomConditionError = false;
      state.showNoSharedPageError = false;
      state.showRedactionCustomConditionError = false;
    },
    clearEditSharingBlockState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSharingDraftBySharingId.fulfilled, (state, action) => {
      state.editedGroupName = action.payload.groupName;
      state.commentExplorerAccess = action.payload.commentExplorerAccess;
      state.summaryAccess = action.payload.summaryAccess;
      state.overviewAccess = action.payload.overviewAccess;
      state.dashboardAccess = action.payload.dashboardAccess;
      state.topicExplorerAccess = action.payload.topicExplorerAccess;
      state.selectedPermissionLevel = action.payload.permissionLevel;
      state.selectedCustomConditions = action.payload.customFilters;
      state.allowExportData = action.payload.allowExport;
      state.selectedRedactionCustomConditions = action.payload.customRedactionVersions ?? [];
      state.allowPin = action.payload.allowPin;
      state.manualRedactionPermission = action.payload.manualRedactionPermission;
      state.visibleDemographicsFilters = action.payload.demographicsToDisplay.map((d) =>
        getVisibleDemFiltersFromData(d, action.payload.availableDemographicFilters)
      );
      state.selectedDemographics = action.payload.demographicFilters.map((d) =>
        getVisibleCommentDemsFromData(d, action.payload.availableDemographicFilters)
      );
      state.selectedRedactionVersion = action.payload.redactionVersions.find(
        (redactionVersion) => redactionVersion.id === action.payload.redactionVersionId
      );
      state.allDataLoaded = true;
    });
  },
});

export const {
  setSelectedUsersCount,
  setEditGroupModalView,
  setSelectedPermissionLevel,
  setSelectedDemographics,
  setSelectedRedactionVersion,
  setSelectedCustomConditions,
  setSelectedRedactionCustomConditions,
  setShowCustomConditionError,
  setShowRedactionCustomConditionError,
  setVisibleDemographicsFilters,
  setManualRedactionPermission,
  setSummaryAccess,
  setShowNoSharedPageError,
  setOverviewAccess,
  setDashboardAccess,
  setTopicExplorerAccess,
  setCommentExplorerAccess,
  setAllowExportData,
  setAllDataLoaded,
  setIsLoading,
  setAllowPin,
  setIsModalOpen,
  setEditedGroupName,
  clearEditSharingBlockState,
  resetSharingErrors,
} = editSharingBlockSlice.actions;

export default editSharingBlockSlice.reducer;
