import millify from "millify";

export const formatTopicsSentimentPercent = (count: number) => {
  let rate = count * 100;

  rate = isNaN(rate) ? 0 : rate;
  if (rate < 1 && rate > 0) return "< 1%";

  return `${Math.round(rate * 100) / 100}%`;
};

export const formatSearchForFetchRequest = (search: string): string => {
  if (!search) return null;
  // If search is equal to an encoded empty string, return search without encoding again
  if (search === "%02%03") return search;
  return encodeURIComponent(search);
};

export const getDayMonthYearFromDate = (date: string): string => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getAbbreviatedNumber = (
  total: number,
  isEmptyState: boolean
): { count: string; isResource?: boolean } => {
  if (isEmptyState) return { count: "totalBlockCount.commentersCount.undefined", isResource: true };
  if (total !== 0 && !total) return null;
  if (total < 9999) return { count: total.toString() };
  return { count: millify(total) };
};
