import React, { useContext } from "react";
import styled from "styled-components";

import { RecommendedComments } from "./RecommendedComments";
import { CollapsibleSection } from "components/CollapsibleSection";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { AnalysisContext } from "context/AnalysisContext";
import { isSettingsPageUnavailable } from "utils/isSettingsPageUnavailable";
import { PillInput } from "components/_inputs/PillInput";
import { useAppDispatch, useAppSelector } from "store";
import {
  clearAllComments,
  removeComment,
  setExcludedCommentList,
} from "store/analysisSettings/analysisSettingsSlice";

export const PreFiltering = () => {
  const [analysisContextState] = useContext(AnalysisContext);

  const state = useAppSelector((state) => state.analysisSettings);
  const dispatch = useAppDispatch();

  return (
    <StyledParentContainer>
      <CollapsibleSection
        titleLeftResource="analysisSettings.prefiltering.header"
        hideHeaderBorder
        hideBoxShadow
        isDisabled={isSettingsPageUnavailable(analysisContextState)}
      >
        <StyledDescriptionHeader>
          <Text resource="analysisSettings.prefiltering.description" />
        </StyledDescriptionHeader>
        <StyledInputsContainer>
          <RecommendedComments />
          <PillInput
            inputHeaderKey="analysisSettings.prefiltering.inputSection.header"
            placeholderKey="analysisSettings.prefiltering.inputSection.placeholder"
            pillList={state.excludedCommentList.map((listItem) => listItem.comment)}
            isSelectAllPill={!!state.excludedCommentList[0]?.selectAllExclusionCount}
            clearAll={() => dispatch(clearAllComments())}
            removeListItem={({ pillItem, index }) =>
              dispatch(removeComment({ commentDetails: pillItem, index }))
            }
            submitListItem={(listItem) =>
              dispatch(
                setExcludedCommentList({
                  comment: listItem,
                  isUserInput: true,
                })
              )
            }
            height={"320px"}
            canEdit
            allowMultipleLinesInput
          />
        </StyledInputsContainer>
      </CollapsibleSection>
    </StyledParentContainer>
  );
};

const StyledParentContainer = styled.div`
  margin: 20px 0;
  font-weight: bold;
`;

const StyledDescriptionHeader = styled.div`
  font-size: 14px;
  color: ${Color.gray40};
  font-weight: normal;
`;

const StyledInputsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-weight: normal;
  width: 100%;
`;
