import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios, { AxiosError } from "axios";

import { useQueryParams } from "hooks/useQueryParams";
import { AnalysisContext } from "context/AnalysisContext";
import { getCommentStatisticsByAnalysisId } from "services/analysis";
import { getCustomModelId } from "utils/getCustomModelId";
import { isAnalyzed } from "utils/isAnalyzed";
import {
  setAnalysisError,
  setCommentStatistics,
  handleAxiosError,
  setLoadingCommentStatistics,
} from "context/AnalysisContext/actions";

import { StyledBlock } from "./_layouts/StyledBlock";
import { HighLevelAttributes } from "./_layouts/blocks/HighLevelAttributes";
import { OverviewSectionTitle } from "./_layouts/OverviewSectionTitle";
import { AlertSection } from "./_layouts/blocks/AlertSection";
import { HighLevelTopics } from "./_layouts/blocks/HighLevelTopics";
import { Text } from "components/Text";

import { SharingOverviewAccess, TopicsListView } from "@explorance/mly-types";
import { TopicView } from "ts/enums/topicView";
import { PageErrorType } from "ts/enums/pageErrorType";
import { ErrorScreen } from "components/ErrorScreen";

export const OverviewPage = () => {
  const [state, dispatch] = useContext(AnalysisContext);

  const { sharingId } = useQueryParams();
  const isSharing = !!state.analysisDetails.sharing;
  const history = useHistory();
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const hasPageAccess =
    !isSharing || state.analysisDetails.sharing.overviewAccess === SharingOverviewAccess.Shared;

  const customModelId = getCustomModelId(state);

  useEffect(() => {
    return () => {
      cancelTokenSourceRef.current.cancel("Overview page unmounted"); // eslint-disable-line
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (!state.loadingAnalysisModels) {
      customModelId
        ? params.set("custom_model_id", customModelId.toString())
        : params.delete("custom_model_id");
      history.replace(history.location.pathname + "?" + params.toString());
    }
  }, [history, customModelId, state.loadingAnalysisModels]); // eslint-disable-line

  // fetch comment statistics
  useEffect(() => {
    const fn = async () => {
      if (isNaN(state.analysisDetails.id)) {
        return dispatch(setAnalysisError(PageErrorType.AnalysisNotFound));
      }
      if (isAnalyzed(state.analysisDetails.status)) {
        dispatch(setCommentStatistics(null));
        dispatch(setLoadingCommentStatistics(true));
        try {
          const { data } = await getCommentStatisticsByAnalysisId(
            state.analysisDetails.id,
            customModelId && customModelId,
            undefined,
            state.previewUser.id,
            sharingId,
            undefined,
            cancelTokenSourceRef.current.token
          );
          dispatch(setCommentStatistics(data.statistics));
          dispatch(setLoadingCommentStatistics(false));
        } catch (err) {
          if (!axios.isCancel(err)) {
            dispatch(handleAxiosError(err as AxiosError<any>));
          }
        }
      }
    };
    if (!state.loadingAnalysisDetails) fn();
    // eslint-disable-next-line
  }, [
    state.analysisDetails.id,
    dispatch,
    state.loadingAnalysisDetails,
    state.previewUser.id,
    customModelId,
  ]);

  if (!hasPageAccess) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <div className="fade-in">
      <OverviewSectionTitle title={<Text resource="overview.commentsSentiment.title" />} />
      <StyledBlock>
        <HighLevelAttributes
          attributeType={TopicsListView.Sentiments}
          isEmptyState={state?.analysisDetails?.availableResults?.sentiments === false}
          sharedUser={state.previewUser}
        />
      </StyledBlock>
      <StyledBlock>
        <HighLevelTopics
          topicView={TopicView.insights}
          isEmptyState={state?.analysisDetails?.availableResults?.topics === false}
          sharedUser={state.previewUser}
        />
      </StyledBlock>
      <OverviewSectionTitle title={<Text resource="overview.recommendations.title" />} />
      <StyledBlock>
        <HighLevelAttributes
          attributeType={TopicsListView.Recommendations}
          isEmptyState={state?.analysisDetails?.availableResults?.recommendations === false}
          sharedUser={state.previewUser}
        />
      </StyledBlock>
      <StyledBlock>
        <HighLevelTopics
          topicView={TopicView.recommendations}
          isEmptyState={state?.analysisDetails?.availableResults?.topics === false}
          sharedUser={state.previewUser}
        />
      </StyledBlock>
      <OverviewSectionTitle title={<Text resource="overview.alerts.title" />} />
      <StyledBlock>
        <AlertSection
          isEmptyState={state?.analysisDetails?.availableResults?.alerts === false}
          sharedUser={state.previewUser}
        />
      </StyledBlock>
    </div>
  );
};
