import { FileUploadStatus } from "@explorance/mly-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECENT_ANALYSIS_LIMIT } from "assets/constants/listLimits";
import { createAnalysis, getRecentAnalyses, uploadFile } from "services/analysis";
import { RecentAnalysis } from "ts/analysis";
import { setAnalysisList } from "./homePageSlice";
import { stripFileExtension } from "utils/stripFileExtension";

export const fetchRecentAnalyses = createAsyncThunk<RecentAnalysis[], number | void, { dispatch }>(
  "homePage/fetchRecentAnalyses",
  async (uploadingId, { dispatch }): Promise<RecentAnalysis[]> => {
    const { data } = await getRecentAnalyses(RECENT_ANALYSIS_LIMIT);

    if (!data.analysis) {
      throw new Error("Failed to fetch recent analysis");
    }

    await dispatch(
      setAnalysisList(
        data.analysis.map((analysis: RecentAnalysis) =>
          analysis.analysisId === uploadingId
            ? { ...analysis, fileUploadStatus: FileUploadStatus.Uploading }
            : analysis
        )
      )
    );

    if (uploadingId !== undefined) {
      return data.analysis.map((analysis: RecentAnalysis) =>
        analysis.analysisId === uploadingId
          ? { ...analysis, fileUploadStatus: FileUploadStatus.Uploading }
          : analysis
      );
    }

    return data.analysis;
  }
);

export const uploadNewFile = createAsyncThunk<string | void, File, { dispatch }>(
  "homePage/uploadNewFile",
  async (file, { dispatch }): Promise<string> => {
    let newAnalysis = null;
    let recentAnalysisList = null;

    try {
      //Create new analysis fetch and set it as uploading
      newAnalysis = (await createAnalysis(stripFileExtension(file.name))).data;
      recentAnalysisList = (await getRecentAnalyses(RECENT_ANALYSIS_LIMIT)).data;
      await dispatch(
        setAnalysisList(
          recentAnalysisList.analysis.map((analysis: RecentAnalysis) =>
            analysis.analysisId === newAnalysis.analysisId
              ? { ...analysis, fileUploadStatus: FileUploadStatus.Uploading }
              : analysis
          )
        )
      );
      await uploadFile(newAnalysis.analysisId, file);
    } catch (error: any) {
      //Reverse uploading state
      await dispatch(
        setAnalysisList(
          recentAnalysisList.analysis.map((analysis: RecentAnalysis) =>
            analysis.analysisId === newAnalysis.analysisId
              ? { ...analysis, fileUploadStatus: FileUploadStatus.Failed }
              : analysis
          )
        )
      );
      return error.response?.data?.details?.[0]?.rule === "string.max"
        ? "toast.fileUpload.fileNameTooLong"
        : error.response.status === 503
        ? "toast.fileUpload.noService"
        : "toast.fileUpload.error";
    }

    return null;
  }
);
