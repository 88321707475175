import { getHeaders, mlyApi } from "config/mlyApi";

export async function fetchRedactionFeatureStatus() {
  const requestConfig = await getHeaders();
  const url = "/features/redaction";
  return mlyApi.get(url, requestConfig);
}

export async function fetchLlmFeatureStatus() {
  const requestConfig = await getHeaders();
  const url = "/app-settings/llm";
  return mlyApi.get(url, requestConfig);
}

export async function fetchLanguageFeatureStatus() {
  const requestConfig = await getHeaders();
  const url = "/features/ui-language";
  return mlyApi.get(url, requestConfig);
}
