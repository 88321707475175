import { DemographicFilterHeader } from "ts/filters/demographicFilter";

export const selectCommenterDemographic = (
  demographic: DemographicFilterHeader,
  selectedDemsCopy: Array<any>
): Array<any> => {
  return selectedDemsCopy.find((sd) => sd.id === demographic.id)
    ? selectedDemsCopy.filter((sd) => sd.id !== demographic.id)
    : [...selectedDemsCopy, demographic];
};

export const selectAllCommenterDemographics = (
  isAllSelected: boolean,
  selectedDemsCopy: Array<any>,
  allDems: Array<any>
): Array<any> => {
  if (isAllSelected) return [];
  allDems.forEach((d) => {
    if (selectedDemsCopy.find((sd) => sd.id === d.id)) return;
    selectedDemsCopy.push(d);
  });
  return selectedDemsCopy;
};
