import { AxiosError } from "axios";
import { PageErrorType } from "ts/enums/pageErrorType";
import { AnalysisModel } from "ts/filters/analysisModel";
import { AnalysisDetails, PreviewUser } from "ts/analysis";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { ApiTopicNode } from "ts/topic";
import { AnalysisErrorBody } from "ts/analysisErrorBody";
import { WidgetsApiData } from "ts/widget";
import { Range } from "ts/range";
import { ExcludedData } from "ts/overview";
import { Category, CommentersCount, CommentsSearchType } from "@explorance/mly-types";
import { SelectOption } from "ts/select";

export enum ResultsActionType {
  SET_SELECTED_MODEL_ID = "SET_SELECTED_MODEL_ID",
  SET_LAST_SELECTED_MODEL_ID = "SET_LAST_SELECTED_MODEL_ID",
  SET_ANALYSIS_ERROR = "SET_ANALYSIS_ERROR",
  SET_ANALYSIS_MODELS = "SET_ANALYSIS_MODELS",
  SET_ANALYSIS_DETAILS = "SET_ANALYSIS_DETAILS",
  SET_COMMENTER_DETAILS = "SET_COMMENTER_DETAILS",
  SET_AVAILABLE_DEMOGRAPHIC_FILTERS = "SET_AVAILABLE_DEMOGRAPHIC_FILTERS",
  SET_SELECTED_DEMOGRAPHIC_FILTERS = "SET_SELECTED_DEMOGRAPHIC_FILTERS",
  SET_LAST_SELECTED_TOPIC_FILTER_IDS = "SET_LAST_SELECTED_TOPIC_FILTER_IDS",
  SET_SELECTED_TOPIC_FILTER_IDS = "SET_SELECTED_TOPIC_FILTER_IDS",
  SET_THRESHOLD = "SET_THRESHOLD",
  SET_ALERT_THRESHOLD = "SET_ALERT_THRESHOLD",
  SET_EMPTY_COMMENTS_DETAILS = "SET_EMPTY_COMMENTS_DETAILS",
  SET_WIDGETS_API_DATA = "SET_WIDGETS_API_DATA",
  SET_COMMENT_STATISTICS = "SET_COMMENT_STATISTICS",
  SET_LOADING_AVAILABLE_DEMOGRAPHIC_FILTERS = "SET_LOADING_AVAILABLE_DEMOGRAPHIC_FILTERS",
  SET_LOADING_ANALYSIS_DETAILS = "SET_LOADING_ANALYSIS_DETAILS",
  SET_LOADING_ANALYSIS_MODELS = "SET_LOADING_ANALYSIS_MODELS",
  SET_LOADING_COMMENT_STATISTICS = "SET_LOADING_COMMENT_STATISTICS",
  SET_LOADING_PREVIEW_USERS = "SET_LOADING_PREVIEW_USERS",
  RESET_ALL_FILTERS = "RESET_ALL_FILTERS",
  RESET_PREVIEW_USER = "RESET_PREVIEW_USER",
  SHOW_WHITE_BACKGROUND = "SHOW_WHITE_BACKGROUND",
  SET_SELECTED_COLUMN_FILTERS = "SET_SELECTED_COLUMN_FILTERS",
  SET_SELECTED_ALERT_RANGE = "SET_SELECTED_ALERT_RANGE",
  SET_PREVIEW_USER = "SET_PREVIEW_USER",
  SET_PREVIEW_USERS = "SET_PREVIEW_USERS",
  SET_LAST_WIDGETS_PUSH = "SET_LAST_WIDGETS_PUSH",
  SET_SELECTED_TRANSLATION_LANGUAGE = "SET_SELECTED_TRANSLATION_LANGUAGE",
  SET_SELECTED_CATEGORIES = "SET_SELECTED_CATEGORIES",
  SET_AVAILABLE_CATEGORIES = "SET_AVAILABLE_CATEGORIES",
  SET_COMMENTS_SEARCH_TYPE = "SET_COMMENTS_SEARCH_TYPE",
  SET_IS_PREVIEW_PRELOADED = "SET_IS_PREVIEW_PRELOADED",
}

export const setSelectedModelId = (modelId: number) => ({
  type: ResultsActionType.SET_SELECTED_MODEL_ID,
  payload: modelId,
});

export const setLastSelectedModelId = (modelId: number) => ({
  type: ResultsActionType.SET_LAST_SELECTED_MODEL_ID,
  payload: modelId,
});

export const handleAxiosError = (error: AxiosError<AnalysisErrorBody>) => {
  console.error(error);
  const errorCode = error?.response?.data.error ?? error?.response?.data.type;
  const getErrorPayload = (errCode) => {
    if (errCode in PageErrorType) {
      return errCode;
    } else if (errCode === "InsufficientPermission") {
      return "AnalysisInsufficientPermission";
    }
    return PageErrorType.Other;
  };

  return {
    type: ResultsActionType.SET_ANALYSIS_ERROR,
    payload: getErrorPayload(errorCode),
  };
};

export const setAnalysisError = (error: PageErrorType) => ({
  type: ResultsActionType.SET_ANALYSIS_ERROR,
  payload: error,
});

export const setAnalysisModels = (analysisModels: AnalysisModel[]) => ({
  type: ResultsActionType.SET_ANALYSIS_MODELS,
  payload: analysisModels,
});

export const setAnalysisDetails = (analysisDetails: AnalysisDetails) => ({
  type: ResultsActionType.SET_ANALYSIS_DETAILS,
  payload: analysisDetails,
});

export const setCommenterDetails = (commenterDetails: CommentersCount) => ({
  type: ResultsActionType.SET_COMMENTER_DETAILS,
  payload: commenterDetails,
});

export const setExcludedDataDetails = (excludedDataDetails: ExcludedData) => ({
  type: ResultsActionType.SET_EMPTY_COMMENTS_DETAILS,
  payload: excludedDataDetails,
});

export const setAvailableDemographicFilters = (
  availableDemographicFilters: DemographicFilter[]
) => ({
  type: ResultsActionType.SET_AVAILABLE_DEMOGRAPHIC_FILTERS,
  payload: availableDemographicFilters,
});

export const setSelectedDemographicFilters = (selectedDemographicFilters: DemographicFilter[]) => ({
  type: ResultsActionType.SET_SELECTED_DEMOGRAPHIC_FILTERS,
  payload: selectedDemographicFilters,
});

export const setSelectedTopicNodes = (selectedTopicNodes: ApiTopicNode[]) => ({
  type: ResultsActionType.SET_SELECTED_TOPIC_FILTER_IDS,
  payload: selectedTopicNodes,
});

export const setLastSelectedTopicNodes = (selectedTopicNodes: ApiTopicNode[]) => ({
  type: ResultsActionType.SET_LAST_SELECTED_TOPIC_FILTER_IDS,
  payload: selectedTopicNodes,
});

export const setThreshold = (thresholdValue: number) => ({
  type: ResultsActionType.SET_THRESHOLD,
  payload: thresholdValue,
});

export const setAlertThreshold = (alertThreshold: number) => ({
  type: ResultsActionType.SET_ALERT_THRESHOLD,
  payload: alertThreshold,
});

export const setWidgetsApiData = (widgetsApiData: WidgetsApiData) => ({
  type: ResultsActionType.SET_WIDGETS_API_DATA,
  payload: widgetsApiData,
});

export const setCommentStatistics = (commentStatistics: any) => ({
  type: ResultsActionType.SET_COMMENT_STATISTICS,
  payload: commentStatistics,
});

export const setLoadingAnalysisModels = (loadingAnalysisModels: boolean) => ({
  type: ResultsActionType.SET_LOADING_ANALYSIS_MODELS,
  payload: loadingAnalysisModels,
});

export const setLoadingAvailableDemographicFilters = (
  loadingAvailableDemographicFilters: boolean
) => ({
  type: ResultsActionType.SET_LOADING_AVAILABLE_DEMOGRAPHIC_FILTERS,
  payload: loadingAvailableDemographicFilters,
});

export const setLoadingAnalysisDetails = (loadingAnalysisDetails: boolean) => ({
  type: ResultsActionType.SET_LOADING_ANALYSIS_DETAILS,
  payload: loadingAnalysisDetails,
});

export const setLoadingPreviewUsers = (loadingPreviewUsers: boolean) => ({
  type: ResultsActionType.SET_LOADING_PREVIEW_USERS,
  payload: loadingPreviewUsers,
});

export const setLoadingCommentStatistics = (loadingCommentStatistics: boolean) => ({
  type: ResultsActionType.SET_LOADING_COMMENT_STATISTICS,
  payload: loadingCommentStatistics,
});

export const resetAllFilters = (resetSelectedModel?: boolean) => ({
  type: ResultsActionType.RESET_ALL_FILTERS,
  payload: resetSelectedModel,
});

export const resetPreviewUser = () => ({
  type: ResultsActionType.RESET_PREVIEW_USER,
  payload: null,
});

export const showWhiteBackground = (showWhiteBackground: boolean) => ({
  type: ResultsActionType.SHOW_WHITE_BACKGROUND,
  payload: showWhiteBackground,
});

export const setSelectedColumnFilters = (selectedColumnFilters: string[]) => ({
  type: ResultsActionType.SET_SELECTED_COLUMN_FILTERS,
  payload: selectedColumnFilters,
});

export const setSelectedAlertRange = (selectedAlertRange: Range) => ({
  type: ResultsActionType.SET_SELECTED_ALERT_RANGE,
  payload: selectedAlertRange,
});

export const setPreviewUser = (previewUser: PreviewUser) => ({
  type: ResultsActionType.SET_PREVIEW_USER,
  payload: previewUser,
});

export const setPreviewUsers = (previewUsers: PreviewUser[]) => ({
  type: ResultsActionType.SET_PREVIEW_USERS,
  payload: previewUsers,
});

export const setLastWidgetsPush = (lastPushed: string, pushAvailable: boolean) => ({
  type: ResultsActionType.SET_LAST_WIDGETS_PUSH,
  payload: { lastPushed, pushAvailable },
});

export const setSelectedTranslationLanguage = (selectedLanguage: SelectOption) => ({
  type: ResultsActionType.SET_SELECTED_TRANSLATION_LANGUAGE,
  payload: selectedLanguage.value,
});

export const setAvailableCategories = (categories: Category[]) => ({
  type: ResultsActionType.SET_AVAILABLE_CATEGORIES,
  payload: categories,
});

export const setSelectedCategories = (categories: string[]) => ({
  type: ResultsActionType.SET_SELECTED_CATEGORIES,
  payload: categories,
});

export const setCommentsSearchType = (searchType: CommentsSearchType) => ({
  type: ResultsActionType.SET_COMMENTS_SEARCH_TYPE,
  payload: searchType,
});

export const setIsPreviewPreloaded = (preloaded: boolean) => ({
  type: ResultsActionType.SET_IS_PREVIEW_PRELOADED,
  payload: preloaded,
});
