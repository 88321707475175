import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Notification, NotificationType, TaskStatus } from "@explorance/mly-types";
import { routes } from "routes";
import { Color } from "ts/enums/color";

import { Text } from "components/Text";
import { useAppSelector } from "store";
import { getDefaultAnalysisPage } from "utils/getDefaultAnalysisPage";

type Props = {
  notification: Notification;
  notificationCenterCloseHandler: () => void;
  retryExportFunc?: () => void;
  downloadExportFunc?: () => void;
};

export const ActionButtonContainer = ({
  notification,
  notificationCenterCloseHandler,
  retryExportFunc,
  downloadExportFunc,
}: Props) => {
  // hooks
  const settingsState = useAppSelector((state) => state.settings);
  const history = useHistory();

  // variables
  const exportSuccess =
    notification.type === NotificationType.Export &&
    notification.message.status === TaskStatus.Success;
  const exportFailure =
    notification.type === NotificationType.Export &&
    notification.message.status === TaskStatus.Failure;
  const importSuccess =
    notification.type === NotificationType.Import &&
    notification.message.status === TaskStatus.Success;
  const importFailure =
    notification.type === NotificationType.Import &&
    notification.message.status === TaskStatus.Failure;
  const analysisSuccess =
    notification.type === NotificationType.Analysis &&
    notification.message.status === TaskStatus.Success;
  const analysisFailure =
    notification.type === NotificationType.Analysis &&
    notification.message.status === TaskStatus.Failure;
  const updatedAnalysis =
    notification.type === NotificationType.SharedAnalysis &&
    notification.message.status === TaskStatus.Success;

  // functions
  const getButtonText = (): React.JSX.Element => {
    if (importSuccess || importFailure || analysisFailure) {
      return <Text resource={"notificationCard.button.viewSettings"} />;
    }
    if (analysisSuccess || updatedAnalysis) {
      return settingsState.features.llm && analysisSuccess ? (
        <Text resource={`notificationCard.button.analysisSuccessSummary`} />
      ) : (
        <Text resource={`notificationCard.button.analysis${notification.message.status}`} />
      );
    }
    if (exportSuccess || exportFailure) {
      return <Text resource={`notificationCard.button.export${notification.message.status}`} />;
    }
  };

  const handleClick = async () => {
    notificationCenterCloseHandler();
    if (importSuccess || importFailure || analysisFailure)
      return history.push(routes.settingsPage(notification.message.analysisId));
    if (analysisSuccess || updatedAnalysis)
      return history.push(
        await getDefaultAnalysisPage(notification.message.analysisId, settingsState.features.llm)
      );
    if (exportSuccess) return history.push(routes.downloadedFilesPage);
    if (exportFailure) return retryExportFunc();
  };

  if (!getButtonText()) return null;

  return (
    <StyledActionButtonContainer>
      <Button size={ButtonSize.sm} variant={ButtonVariant.outlineBlue} onClick={handleClick}>
        <StyledButtonContentWrapper>
          {getButtonText()}

          <Icon
            type={IconType.chevronRight}
            color={Color.blue50}
            size={14}
            style={{ marginTop: "2.5px" }}
          />
        </StyledButtonContentWrapper>
      </Button>
      {exportSuccess && (
        <Button size={ButtonSize.sm} variant={ButtonVariant.primary} onClick={downloadExportFunc}>
          <Text resource="notificationCard.button.download" />
        </Button>
      )}
    </StyledActionButtonContainer>
  );
};

const StyledActionButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
