import React from "react";
import { Select } from "components/_inputs/Select";
import { Text } from "components/Text";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";
import { ButtonSize } from "ts/enums/button";
import { LanguageCode } from "@explorance/mly-types";
import { useAppSelector } from "store";

type Props = {
  currentLanguage: LanguageCode;
  handleSelectLanguage: (lang: LanguageCode) => void;
};

export const LanguageSelect = ({ currentLanguage, handleSelectLanguage }: Props) => {
  const state = useAppSelector((state) => state.settings);
  const getSelectOption = (languageCode: LanguageCode) => {
    return {
      label: <Text resource={`languageCode.${languageCode}.userLanguageName`} />,
      value: languageCode,
    };
  };

  return (
    <Select
      selectedOption={getSelectOption(currentLanguage)}
      options={state.uiLanguages.map((lc) => getSelectOption(lc))}
      buttonSize={ButtonSize.md}
      buttonStyle={{
        position: "relative",
        fontWeight: "normal",
        fontSize: "15px",
        width: "100%",
      }}
      dropdownPosition={{ right: 0 }}
      dropdownWidth="100%"
      flex="1 0 auto"
      disabled={!IsFeatureActive(Feature.MultiLingualUi)}
      handleChange={handleSelectLanguage}
    />
  );
};
